<template>
    <div v-if="scatterChartsData" class="scatter-chart-container">
        <div class="chart-grid-container">
            <div v-for="(chartData, index) in scatterChartsData" :key="index" class="scatter-chart-box borderStyle">
                <div class="scatter-header">
                    <div class="scatter-title">{{ chartData.name }}</div>
                    <button class="icon-button" @click="showModal(index)" aria-label="Open details modal">
                        <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="scatter-chart" :ref="'scatterChart'"></div>
            </div>
        </div>
        <b-modal id="details-modal" v-if="currentTableData" title="독립변수 상관도 지표" @hide="closeModal" hide-footer>
            <div class="table-container" style="overflow-y: auto; max-height: 70vh;">
                <div>
                    <h5>분석식</h5>
                    <div>Y = {{ currentTableData.equation }}</div>
                </div>
                <br />
                <div>
                    <h5>분석 지표</h5>
                    <b-table-simple bordered class="text-center">
                        <b-thead>
                            <b-tr>
                                <b-th>지표</b-th>
                                <b-th colspan="2">지표값</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th :rowspan="currentTableData.coefficients.length + 1">회귀계수</b-th>
                                <b-th>Y절편</b-th>
                                <b-td>{{ currentTableData.coefficients[0] || 0 }}</b-td>
                            </b-tr>
                            <b-tr v-for="(cfc, cfcIndex) in currentTableData.coefficients" :key="cfcIndex + 10">
                                <b-th v-if="cfcIndex !== 0">{{ `X${cfcIndex}계수` }}</b-th>
                                <b-td v-if="cfcIndex !== 0">{{ cfc || 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>R2</b-th>
                                <b-td colspan="2">{{ currentTableData.R2 || 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>보정 R2</b-th>
                                <b-td colspan="2">{{ currentTableData.adjustedR2 || 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th :rowspan="currentTableData.pValues.length + 1">P-Value</b-th>
                                <b-th>Y절편</b-th>
                                <b-td>{{ currentTableData.pValues[0] || 0 }}</b-td>
                            </b-tr>
                            <b-tr v-for="(pVal, pValIndex) in currentTableData.pValues" :key="pValIndex + 20">
                                <b-th v-if="pValIndex !== 0">{{ `X${pValIndex}계수` }}</b-th>
                                <b-td v-if="pValIndex !== 0">{{ pVal || 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th :rowspan="currentTableData.vif.length + 1">다중공선성</b-th>
                                <b-th>X1</b-th>
                                <b-td>{{ currentTableData.vif[0] || 0 }}</b-td>
                            </b-tr>
                            <b-tr v-for="(vif, vifIndex) in currentTableData.vif" :key="vifIndex + 30">
                                <b-th v-if="vifIndex !== 0">{{ `X${vifIndex + 1}` }}</b-th>
                                <b-td v-if="vifIndex !== 0">{{ vif || 0 }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
                <br />
                <div v-if="currentTableData.evalResult" class="eval-result">
                    <h5>분석 결과</h5>
                    <div v-for="(sentence, i) in currentTableData.evalResult" :key="i">
                        {{ sentence }}
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import * as echarts from "echarts";
import ecStat from "echarts-stat";

export default {
    props: ["resultData", "detailInfo"],
    data() {
        return {
            scatterChartsData: [],
            activeIcon: "statisticReport",
            evalResult: "분석 결과 내용입니다.",
            currentTableData: null,

            item: {
                variableEvaluation: {
                    equation: "3.2x + 7.5",
                    coefficients: [7.5, 3.2],
                    R2: 0.89,
                    adjustedR2: 0.87,
                    pValues: [0.01, 0.03],
                    vif: [1.2, 1.1],
                },
            },
        };
    },
    watch: {
        resultData(newVal) {
            this.scatterChartsData = this.processScatterChartData(newVal.data.baseline);
            this.$nextTick(() => {
                this.renderCharts();
            });
        },
    },
    creted() {
        // this.scatterChartsData = this.processScatterChartData(this.resultData.data.baseline);
    },
    mounted() {
        this.scatterChartsData = this.processScatterChartData(this.resultData.data.baseline);
        // this.$nextTick(() => {
        //     this.renderCharts();
        // });
    },
    computed: {
        mainKey() {
            return this.resultData.variable.main[0].key;
        },
        mainInfo() {
            return [this.mainKey, `${this.mainKey}_adjusted`, `${this.mainKey}_converted`, "regDt"];
        },
    },
    methods: {
        showModal(index) {
            this.currentTableData = this.scatterChartsData[index].tableData;
            setTimeout(() => {
                this.$bvModal.show("details-modal");
            }, 100);
        },
        closeModal() {
            this.currentTableData = null;
        },

        findExactIndependentStats(data, targetIndependent, mainKey) {
            if (
                !this.resultData ||
                !this.resultData.variable ||
                !this.resultData.variable.main ||
                !this.resultData.variable.main.some((main) => main.key === mainKey)
            ) {
                console.warn(`Main key ${mainKey}는 resultData에 초기화되지 않았습니다.`);
                return [];
            }

            if (!data || !data[mainKey]) {
                console.warn(`Main key ${mainKey}에 대한 데이터가 없습니다.`);
                return [];
            }

            return data[mainKey].filter(
                (item) => item.independents.length === 1 && item.independents[0] === targetIndependent
            );
        },

        processScatterChartData(baseData) {
            const scatterChartsData = [];

            this.resultData.variable.main.forEach((main) => {
                const mainKey = main.key;
                // const mainName = main.ptName;

                // independ의 key들만 가져오기
                const independKeys = this.resultData.variable.independ.map((independ) => independ.key);

                independKeys.forEach((xKey) => {
                    if (!baseData[xKey] || !baseData[mainKey]) {
                        console.warn(`데이터가 부족하여 ${mainKey}와 ${xKey}의 차트를 생성할 수 없습니다.`);
                        return;
                    }

                    // xKey와 mainKey로 데이터 매핑
                    const combinedData = baseData[xKey].map((xValue, idx) => ({
                        x: xValue,
                        y: baseData[mainKey][idx],
                        regDt: baseData.regDt[idx],
                    }));

                    scatterChartsData.push({
                        name: `${mainKey} vs ${xKey}`,
                        mainKey,
                        xKey,
                        data: combinedData.map((point) => [point.x, point.y, point.regDt]),
                        tableData: {
                            ...this.findExactIndependentStats(this.resultData.model, xKey, mainKey)[0],
                        },
                    });
                });
            });

            return scatterChartsData;
        },

        renderCharts() {
            this.$nextTick(() => {
                this.scatterChartsData.forEach((data, index) => {
                    const realData = data.data;
                    // const realName = data.name;
                    const mainKey = data.mainKey;
                    const chartContainer = this.$refs.scatterChart[index];

                    if (!chartContainer) {
                        console.warn(`Index ${index}의 차트 컨테이너를 찾을 수 없습니다.`);
                        return;
                    }

                    const chart = echarts.init(chartContainer);

                    const regression = ecStat.regression("linear", realData);

                    regression.points = regression.points.map((point) => [
                        parseFloat(point[0].toFixed(3)),
                        parseFloat(point[1].toFixed(3)),
                    ]);

                    const xValues = realData.map((point) => point[0]).filter((val) => val);
                    const minX = Math.min(...xValues);
                    const maxX = Math.max(...xValues);

                    const yValues = realData.map((point) => point[1]).filter((val) => val);
                    // const minY = Math.min(...yValues);
                    const maxY = Math.max(...yValues);

                    const option = {
                        grid: { top: "5%", bottom: "10%", left: "13%", right: "7%" },
                        tooltip: {
                            trigger: "item",
                            formatter: (params) => {
                                const { value } = params.data;
                                return `
                                <div style="font-size: 12px;">
                                    <strong>Date: ${value[2]}</strong><br/>
                                    X (${data.xKey}): ${value[0]}<br/>
                                    Y (${mainKey}): ${value[1]}
                                </div>
                            `;
                            },
                        },
                        xAxis: {
                            type: "value",
                            name: data.xKey,
                            min: minX,
                            max: maxX,
                            nameLocation: "middle",
                            nameGap: 25,
                        },
                        yAxis: {
                            type: "value",
                            // name: mainKey,
                            // min: minY,
                            max: maxY,
                            nameLocation: "middle",
                            nameGap: 25,
                        },
                        series: [
                            {
                                name: "Data Points",
                                type: "scatter",
                                data: realData.map((point) => ({
                                    value: [point[0], point[1], point[2]],
                                })),
                            },
                            {
                                name: "Regression Line",
                                type: "line",
                                data: regression.points,
                                smooth: true,
                                showSymbol: false,
                                lineStyle: { color: "#ff0000" },
                            },
                        ],
                    };

                    chart.setOption(option);
                    window.addEventListener("resize", () => chart.resize());
                });
            });
        },
    },
};
</script>

<style scoped>
.icon-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: inherit;
    font-size: inherit;
}

.icon-button:focus {
    outline: none;
    box-shadow: none;
}

.icon-button:hover {
    color: inherit;
}
.scatter-header {
    display: flex;
    justify-content: space-between;
    padding: 13px;
}
.scatter-title {
    font-size: 11px;
    font-weight: bold;
}
.scatter-chart-box {
    width: 100%;
    margin-right: 12px;
}
.borderStyle {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.scatter-chart-container {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.chart-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    gap: 20px;
}
.scatter-chart {
    /* flex: 1; */
    height: 220px;
    margin: 5px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.statistic-table {
    min-height: 400px;
    width: 30%;
}
.statisticButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.status-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    background-color: #c7c7c7;
}

.status-icon.active {
    color: #fff;
    background-color: #555;
}
.status-icon:focus,
.status-icon:active {
    outline: none;
    box-shadow: none;
}
.msgBox {
    margin-top: 4px;
    padding: 4px;
    border: solid #bbb 1px;
    border-radius: 5px;
    margin-bottom: 16px;
    /* min-height: 350px;
    max-height: 350px; */
    height: 300px;
    overflow-y: auto;
}
.equationContainer {
    display: flex;
    justify-content: center;
    background-color: #555;
    color: yellow;
    border-radius: 5px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding: 4px 3px;
}
</style>
