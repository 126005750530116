<template>
    <div style="width: 100%; height: 100%;">
        <div ref="compareLineChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";

export default {
    props: ["detailInfo", "chartData", "chartDisp", "chartResize", "chartIndex", "hideInfoPanel"],
    components: {},
    data() {
        return {
            chart: null,
            baseName: "",
            baseDataset: [],
            xAxisLabel: [],
            unitMap: {},
        };
    },
    computed: {
        chartType() {
            return !this.isEmpty(this.detailInfo.dispType) && this.detailInfo.dispType !== "Area"
                ? this.detailInfo.dispType.toLowerCase()
                : "line";
        },
    },
    watch: {
        chartData() {
            this.prepareChart();
        },
        chartDisp() {
            if (this.chart) {
                this.chart.resize(); // chartDisp 변경 시 차트를 리사이즈
            }
        },
        chartResize() {
            if (this.chart) {
                this.chart.resize(); // chartResize 변경 시 차트를 리사이즈
            }
        },
        hideInfoPanel() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
    created() {},
    mounted() {
        this.prepareChart();
    },
    methods: {
        async prepareChart() {
            this.xAxisLabel = [];
            if (!this.isEmpty(this.chartData) && !this.isEmpty(this.chartData.header)) {
                // x 축 라벨 세팅
                if (this.detailInfo.timeDsvn == "HOUR") {
                    this.chartData.regDt.forEach((reg) => this.xAxisLabel.push(moment(new Date(reg)).format("HH:mm")));
                } else if (this.detailInfo.timeDsvn == "DAY") {
                    this.chartData.regDt.forEach((reg) =>
                        this.xAxisLabel.push(moment(new Date(reg)).format("YYYY-MM-DD"))
                    );
                } else if (this.detailInfo.timeDsvn == "MONTH") {
                    this.chartData.regDt.forEach((reg) =>
                        this.xAxisLabel.push(moment(new Date(reg)).format("YYYY-MM-DD"))
                    );
                } else if (this.detailInfo.timeDsvn == "YEAR") {
                    this.chartData.regDt.forEach((reg) =>
                        this.xAxisLabel.push(moment(new Date(reg)).format("YYYY-MM"))
                    );
                } else {
                    this.chartData.regDt.forEach((reg) =>
                        this.xAxisLabel.push(moment(new Date(reg)).format("YYYY-MM-DD HH:mm:ss"))
                    );
                }
                this.baseName = this.chartData.header.ptName ?? "";
                this.baseDataset = this.chartData.value.map((val) => {
                    let value = val ? val : 0;
                    return value;
                });
                const baseUnit = this.$store.state.units.find(
                    (unit) => unit.value == this.chartData.header.unitSmallCode
                );
                this.unitMap[this.baseName] = this.chartData.header.dispUnit
                    ? this.chartData.header.dispUnit
                    : baseUnit.text;
            }
            await this.renderChart();
        },
        renderChart() {
            this.chart = echarts.init(this.$refs.compareLineChart);
            // const unitMap = this.unitMap;

            const series = [
                {
                    name: this.baseName,
                    data: this.baseDataset,
                    smooth: 0.4,
                    symbolSize: 3,
                    type: this.chartType,
                    lineStyle: {
                        color: "#9FA8DA",
                        width: 2,
                    },
                    itemStyle: {
                        color: "#9FA8DA",
                    },
                },
            ];

            if (this.detailInfo.dispType == "Area") {
                series[0].areaStyle = {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#9FA8DA" + "00" },
                        { offset: 1, color: "#9FA8DA" },
                    ]),
                };
            }

            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                    formatter: function(params) {
                        // 값 축약 함수
                        function formatValue(value) {
                            if (value >= 1e9) {
                                return Number.isInteger(value / 1e9)
                                    ? value / 1e9 + "G"
                                    : (value / 1e9).toFixed(1) + "G";
                            } else if (value >= 1e6) {
                                return Number.isInteger(value / 1e6)
                                    ? value / 1e6 + "M"
                                    : (value / 1e6).toFixed(1) + "M";
                            } else if (value >= 1e3) {
                                return Number.isInteger(value / 1e3)
                                    ? value / 1e3 + "K"
                                    : (value / 1e3).toFixed(1) + "K";
                            } else {
                                return value ? value : "-";
                            }
                        }

                        let result = `<div style="font-size: 12px; width: 230px;">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((param) => {
                            result += `<div style="display: flex; justify-content: space-between;">
                                                <div>
                                                    ${param.marker}
                                                    <span>${param.seriesName}</span>
                                                </div>
                                                <span style="font-weight: bold;">
                                                    ${formatValue(param.data)}
                                                </span>
                                        </div>`;
                        });

                        result += `</div></div>`;
                        return result;
                    },
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.xAxisLabel,
                },
                yAxis: {
                    name: `${this.baseName} (${this.unitMap[this.baseName]})`,
                    nameLocation: "end", // "start", "middle", "end" 중 선택
                    nameTextStyle: {
                        // fontSize: 14, // 텍스트 크기
                        // color: "#333", // 텍스트 색상
                        padding: [0, 0, 0, 150], // 여백 설정
                    },
                    type: "value",
                    axisLabel: {
                        align: "right",
                        formatter: function(value) {
                            if (value >= 1e9) {
                                return Number.isInteger(value / 1e9)
                                    ? value / 1e9 + "G"
                                    : (value / 1e9).toFixed(1) + "G";
                            } else if (value >= 1e6) {
                                return Number.isInteger(value / 1e6)
                                    ? value / 1e6 + "M"
                                    : (value / 1e6).toFixed(1) + "M";
                            } else if (value >= 1e3) {
                                return Number.isInteger(value / 1e3)
                                    ? value / 1e3 + "K"
                                    : (value / 1e3).toFixed(1) + "K";
                            } else {
                                return Number.isInteger(value) ? value.toString() : value.toFixed(1);
                            }
                        },
                    },
                },
                series: series,
            };
            this.chart.setOption(option);

            this.addHoverSync();

            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
        addHoverSync() {
            // 마우스 hover 이벤트
            this.chart.on("mousemove", (event) => {
                this.$emit("hover-event", { index: this.chartIndex, event }); // index와 이벤트 데이터 전달
            });

            // 마우스 leave 이벤트
            this.chart.on("mouseout", () => {
                this.$emit("hover-event", { index: this.chartIndex, event: null }); // index와 null 전달
            });
        },
        applyHoverEvent(event) {
            if (event) {
                this.chart.dispatchAction({
                    type: "showTip",
                    seriesIndex: event.seriesIndex,
                    dataIndex: event.dataIndex,
                });
            } else {
                this.chart.dispatchAction({
                    type: "hideTip",
                });
            }
        },
    },
};
</script>
