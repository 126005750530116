<template>
    <div>
        <div class=" reportTable">
            <b-table-simple class="text-center reportTable tableCellBorder">
                <b-thead class="sticky-header">
                    <b-tr>
                        <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">구분</b-td>
                        <b-td rowspan="2" :style="{ backgroundColor: '#ECEFF1' }" class="align-middle">일자</b-td>
                        <b-td :colspan="resultData.variable.main.length" class="dependVar">종속변수 (측정)</b-td>
                        <b-td :colspan="resultData.variable.main.length" class="dependVar">종속변수 (환산)</b-td>
                        <b-td :colspan="independVar.length" :style="{ backgroundColor: '#ECEFF1' }">독립 변수</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td v-for="main in resultData.variable.main" :key="`${main.key}_original`" class="dependVar">
                            {{ main.key }} ({{ main.factor.targetUnit }})
                            <!-- 기본값 -->
                        </b-td>
                        <b-td v-for="main in resultData.variable.main" :key="`${main.key}_converted`" class="dependVar">
                            {{ main.key }} 환산 ({{ main.factor.cnvtUnit }})
                            <!-- 변환값 -->
                        </b-td>

                        <!-- 독립변수 -->
                        <b-td
                            v-for="(item, i) in independVar"
                            :key="`independ_${i}`"
                            :style="{ backgroundColor: '#ECEFF1' }"
                        >
                            {{ item.key }} ({{ units(item.unit) }})
                        </b-td>
                    </b-tr>
                </b-thead>

                <b-tbody v-for="(report, reportIndex) in resultData.data.report" :key="reportIndex">
                    <b-tr
                        v-for="(date, dateIndex) in report.regDt"
                        :key="`${reportIndex}-${dateIndex}`"
                        :class="{ errorBackground: calcFalsyValue(report[mainKey][dateIndex]) }"
                    >
                        <b-td v-if="dateIndex === 0" :rowspan="report.regDt.length + 1" class="align-middle">
                            {{ `${periodName[reportIndex + 1]}` }}
                        </b-td>
                        <b-td>{{ detailInfo.timeDsvn === "HOURBYDAY" ? date.split("T")[1] : date }}</b-td>

                        <b-td v-for="(main, j) in resultData.variable.main" :key="`${main.key}_original_${j}`">
                            {{ report[main.key][dateIndex] ?? "-" }}
                            <!-- 기본값 -->
                        </b-td>
                        <b-td v-for="(main, j) in resultData.variable.main" :key="`${main.key}_converted_${j}`">
                            {{ report[mainInfo.find((info) => info.key == main.key).converted][dateIndex] ?? "-" }}
                            <!-- 변환값 -->
                        </b-td>

                        <!-- 독립 변수 -->
                        <b-td v-for="(independ, o) in independVar" :key="o">
                            {{ report[independ.key][dateIndex] ?? "-" }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["resultData", "detailInfo", "periodName"],
    data() {
        return {
            adjustedName: [],
            excludedKeys: ["regDt", "sumValue", "type", "value"],
            adjustedMap: new Map(),
            reportAdjustData: [],
            sumAdjustData: [],
        };
    },
    watch: {
        resultData: {
            handler(newVal) {
                this.transformAdjustData(newVal);
                this.sumAdjustDataByRegDt(newVal.adjustData);
            },
            deep: true,
        },
    },
    mounted() {
        this.transformAdjustData(this.resultData);
        this.sumAdjustDataByRegDt(this.resultData.adjustData);
    },

    computed: {
        ...mapState({
            envUnit: (state) => state.units,
        }),
        mainKey() {
            return this.resultData.variable.main[0].key;
        },
        mainInfo() {
            return this.resultData.variable.main.map((item) => {
                return {
                    key: item.key,
                    adjusted: `${item.key}_adjusted`,
                    converted: `${item.key}_converted`,
                };
            });
        },
        adjustedNames() {
            // report 객체와 mainKey 데이터가 있는지 확인
            const reportData = this.resultData.adjustData?.report?.[this.mainKey];
            return reportData ? Object.keys(reportData) : []; // 데이터가 없으면 빈 배열 반환
        },
        independVar() {
            return this.resultData.variable.independ.map((item) => {
                return {
                    key: item.key,
                    unit: item.unitSmallCode,
                };
            });
        },
    },
    methods: {
        sumAdjustDataByRegDt(adjustData) {
            if (!adjustData || !adjustData.report || !this.mainKey) {
                console.error("Invalid adjustData format or mainKey is missing");
                return [];
            }

            const report = adjustData.report[this.mainKey];
            if (!report) {
                console.error("No data found for the specified mainKey");
                return [];
            }

            const result = [];
            const regDt = this.resultData.data.report.reduce((acc, item) => {
                return acc.concat(item.regDt); // concat 결과를 반환하여 acc에 저장
            }, []);
            // const regDtList = this.resultData.data.report.regDt; // 날짜 리스트

            for (let i = 0; i < regDt.length; i++) {
                const summedRow = { regDt: regDt[i], total: 0 };

                Object.keys(report).forEach((adjustKey) => {
                    const value = report[adjustKey][i] || 0; // 해당 regDt의 adjust 값 가져오기
                    summedRow.total += value;
                });

                result.push(summedRow);
            }

            this.sumAdjustData = result;
            return;
        },
        transformAdjustData(resultData) {
            if (!resultData.adjustData || !resultData.adjustData.report) {
                return;
            }
            const regDt = resultData.data.report.reduce((acc, item) => {
                return acc.concat(item.regDt); // concat 결과를 반환하여 acc에 저장
            }, []);
            // const regDt = resultData.data.report.regDt;
            const adjustments = resultData.adjustData.report[this.mainKey];
            if (!adjustments || typeof adjustments !== "object") {
                console.error(`Adjustments is invalid for mainKey: ${this.mainKey}`, adjustments);
                return;
            }

            // 조정 키를 동적으로 추출
            const adjustmentKeys = Object.keys(adjustments);

            const transformedData = regDt
                .map((date, index) => {
                    // 각 조정 값을 동적으로 추가
                    const adjustmentValues = adjustmentKeys.reduce((acc, key) => {
                        acc[key] = adjustments[key][index] || 0;
                        return acc;
                    }, {});

                    // 합계 계산
                    const sum = adjustmentKeys.reduce((total, key) => total + (adjustmentValues[key] || 0), 0);

                    // 객체 생성
                    const rowData = {
                        regDt: date,
                        ...adjustmentValues, // 동적으로 추가된 조정 값들
                        sum,
                    };

                    // 모든 값이 0인지 확인 (regDt와 sum은 제외)
                    const allZero = adjustmentKeys.every((key) => adjustmentValues[key] === 0);

                    // 모든 값이 0이 아니면 포함
                    return allZero ? null : rowData;
                })
                .filter(Boolean); // null 값 제거

            this.reportAdjustData = transformedData;
            return;
        },
        calculateColumnSum(name) {
            // 특정 컬럼(name)의 합계를 계산
            return this.reportAdjustData.reduce((total, row) => {
                const value = parseFloat(row[name]) || 0;
                return total + value;
            }, 0);
        },

        calculateTotalSumAdjust() {
            // 합계(sum) 컬럼의 전체 합계를 계산
            return this.reportAdjustData.reduce((total, row) => {
                const value = parseFloat(row.sum) || 0;
                return total + value;
            }, 0);
        },
        addToAdjustedMap(newData) {
            const filteredData = this.filterCustomKeys(newData);

            this.adjustedMap.set(newData.regDt, filteredData);

            this.adjustedMap = new Map(
                Array.from(this.adjustedMap.entries()).sort((a, b) => new Date(a[0]) - new Date(b[0]))
            );
        },

        filterCustomKeys(object) {
            const filteredObject = Object.fromEntries(
                Object.entries(object).filter(([key]) => !this.excludedKeys.includes(key))
            );
            return filteredObject;
        },
        units(unitValue) {
            const foundUnit = this.envUnit.find((item) => item.value === unitValue);
            return foundUnit ? foundUnit.text : "Unknown Unit";
        },
        calcFalsyValue(value) {
            return value == undefined || value === 0 || value == null ? 1 : 0;
        },
    },
};
</script>
<style scoped>
.dependVar {
    background-color: #cfd8dc !important;
}
.errorBackground {
    background-color: #e57373;
    color: white;
}
.smallTitle {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 5px;
}
.reportTable {
    /* max-height: 420px; */
    /* overflow-y: auto; */
    margin-bottom: 15px;
}
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #eceff1;
}
.boldFont {
    font-weight: bold;
}
</style>
