<template>
    <div>
        <div v-if="mnvReportData.analysisStatus === 'Success'">
            <div class="mnvSummaryPanel">
                <div class="rowInvolveChart">
                    <b-table-simple bordered class="text-center mt-3 mnvsummaryTable tableCellBorder">
                        <b-thead class="table-header-bg">
                            <b-tr class="table-header-important">
                                <b-td>
                                    구분
                                </b-td>
                                <b-td>
                                    값
                                </b-td>
                                <b-td>
                                    단위
                                </b-td>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td class="align-middle table-header-important">
                                    실제 에너지 사용량
                                </b-td>
                                <b-th class="align-middle">
                                    {{ mnvReportData.analysisOverview.summary.report.measure }}
                                </b-th>
                                <b-th class="align-middle">
                                    {{ getConvertUint(mainKey[0]) }}
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-td class="table-header-important align-middle">
                                    모델 사용량
                                </b-td>
                                <b-th class="align-middle">
                                    {{ mnvReportData.analysisOverview.summary.report.predict }}
                                </b-th>
                                <b-th class="align-middle">
                                    {{ getConvertUint(mainKey[0]) }}
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-td class="table-header-important align-middle">
                                    절감량 <br />
                                    (모델 사용량 - 실제 사용량)
                                </b-td>
                                <b-th class="align-middle">
                                    {{ mnvReportData.analysisOverview.summary.report.offset }}
                                </b-th>
                                <b-th class="align-middle">
                                    {{ getConvertUint(mainKey[0]) }}
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-td class="table-header-important align-middle">
                                    누적 개선율
                                </b-td>

                                <b-th class="align-middle">
                                    {{
                                        (
                                            (mnvReportData.analysisOverview.summary.report.offset /
                                                mnvReportData.analysisOverview.summary.report.predict) *
                                            100
                                        ).toFixed(1) ?? "-"
                                    }}
                                </b-th>
                                <b-th class="align-middle">
                                    %
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-td class="table-header-important align-middle">
                                    온실가스 절감량
                                </b-td>
                                <b-th class="align-middle">
                                    {{ mergedDetails[0]?.CO2Emit.toFixed(6) ?? "-" }}
                                </b-th>
                                <b-th class="align-middle">
                                    {{ getCO2Unit(mainKey[0]) }}
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-td class="table-header-important align-middle">
                                    에너지 절감비용
                                </b-td>
                                <b-th class="align-middle">
                                    {{ mergedDetails[0]?.cost.toFixed(0) ?? "-" }}
                                </b-th>
                                <b-th class="align-middle">
                                    원
                                </b-th>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <div
                        class="row mnvchart"
                        style="margin: 1rem 0px 0px 0px !important; height: 360px; border-bottom: 1px solid #ececec;"
                    >
                        <div class="col-9" style="height: 100%;">
                            <MnvChart :mnvReportData="mnvReportData" :periodName="periodName" ref="mnvChartRef" />
                        </div>
                        <div class="col-3 pr-0" style="height: 100%;">
                            <div class="col-12 p-0" style="height: 50%; ">
                                <MnvResultSavingChartPanel :mnvReportData="mnvReportData" ref="mnvSavingChartRef" />
                            </div>
                            <div class="col-12 p-0" style="height: 50%;">
                                <MnvResultImproveChartPanel :mnvReportData="mnvReportData" ref="mnvImproveChart" />
                            </div>
                        </div>
                    </div>
                </div>
                <b-table-simple class="text-center mt-3 tableCellBorder">
                    <b-thead>
                        <b-tr class="table-header-bg">
                            <b-th style="width: 200px;">보고 기간</b-th>
                            <b-th>주 분석 대상</b-th>
                            <b-th>베이스라인 소비량</b-th>
                            <b-th>소비량</b-th>
                            <b-th>절감량</b-th>
                            <b-th>누적 절감량</b-th>
                            <b-th>개선률</b-th>
                            <b-th>누적 개선률</b-th>
                            <b-th>온실가스 절감량</b-th>
                            <b-th>누적 온실가스 절감량</b-th>
                            <b-th>에너지 절감비용</b-th>
                            <b-th>누적 에너지 절감비용</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody
                        v-for="(item, index) in mnvReportData.analysisOverview[mainKey[0]].report.details"
                        :key="`report-table-${index}`"
                    >
                        <b-tr>
                            <b-td class="align-middle" :rowspan="mainKey.length > 1 ? 7 : 1">
                                <p class="mb-0">{{ periodName[index + 1] }}</p>
                                <p class="mb-0">
                                    ({{ mnvReportData.data.report[index].regDt[0] }} ~
                                    {{
                                        mnvReportData.data.report[index].regDt[
                                            mnvReportData.data.report[index].regDt.length - 1
                                        ]
                                    }})
                                </p>
                            </b-td>
                            <b-td class="align-middle">{{ mainKey[0] ?? "-" }}</b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].baseline.measure ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].measure ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].offset ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].accOffset ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].reduce ?? "-" }} %
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].accReduce ?? "-" }}
                                %
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].CO2Emit ?? "-" }}
                                {{ getCO2Unit(mainKey[0]) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].accCO2Emit ?? "-" }}
                                {{ getCO2Unit(mainKey[0]) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].cost ?? "-" }} 원
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[mainKey[0]].report.details[index].accCost ?? "-" }} 원
                            </b-td>
                        </b-tr>
                        <!-- 나머지 주 분석 대상에 대한 행들 (첫번째 항목을 제외한 나머지) -->
                        <b-tr v-for="(key, keyIndex) in mainKey.slice(1)" :key="`${key}-table-row-${keyIndex}`">
                            <b-td class="align-middle">{{ key ?? "-" }}</b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].baseline.measure ?? "-" }}
                                {{ getConvertUint(key) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].measure ?? "-" }}
                                {{ getConvertUint(key) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].offset ?? "-" }}
                                {{ getConvertUint(key) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].accOffset ?? "-" }}
                                {{ getConvertUint(key) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].reduce ?? "-" }} %
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].accReduce ?? "-" }} %
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].CO2Emit ?? "-" }}
                                {{ getCO2Unit(key) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].accCO2Emit ?? "-" }}
                                {{ getCO2Unit(key) }}
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].cost ?? "-" }} 원
                            </b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[index].accCost ?? "-" }} 원
                            </b-td>
                        </b-tr>
                        <b-tr v-if="mainKey.length > 1">
                            <b-td class="font-weight-bold">{{ "합계" }}</b-td>
                            <b-td>
                                {{ mnvReportData.analysisOverview["summary"].baseline.measure ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td>
                                {{ mergedDetails[index].measure.toFixed(1) ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td>
                                {{ mergedDetails[index].offset.toFixed(1) ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td>
                                {{ mergedDetails[index].accOffset.toFixed(1) ?? "-" }}
                                {{ getConvertUint(mainKey[0]) }}
                            </b-td>
                            <b-td>
                                {{ (mergedDetails[index].predict / mergedDetails[index].offset).toFixed(1) ?? "-" }}
                                %
                            </b-td>
                            <b-td>
                                {{ (mergedDetails[index].predict / mergedDetails[index].offset).toFixed(1) ?? "-" }}
                                %
                            </b-td>
                            <b-td>
                                {{ mergedDetails[index].CO2Emit.toFixed(6) ?? "-" }}
                                {{ getCO2Unit(mainKey[0]) }}
                            </b-td>
                            <b-td>
                                {{ mergedDetails[index].accCO2Emit.toFixed(6) ?? "-" }}
                                {{ getCO2Unit(mainKey[0]) }}
                            </b-td>
                            <b-td>{{ mergedDetails[index].cost.toFixed(1) ?? "-" }} 원</b-td>
                            <b-td>{{ mergedDetails[index].accCost.toFixed(1) ?? "-" }} 원</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

                <b-table-simple class="text-center tableCellBorder">
                    <b-thead>
                        <b-tr>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                에너지원
                            </b-th>
                            <b-th
                                :colspan="pvalueHeader.length > 0 ? pvalueHeader.length : 1"
                                class="align-middle table-header-bg"
                            >
                                독립변수 (P-Value)
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                모델 R2
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                수정된 모델 R2
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                모델의 표준 편차 (S)
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                모델의 평균 오차 (MBE)
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                모델의 변동 계수 CvRMSE
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                Model Method
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                Method
                            </b-th>
                            <b-th rowspan="2" class="align-middle table-header-bg">
                                누적개선율(%)
                            </b-th>
                        </b-tr>
                        <b-tr>
                            <b-td v-for="(item, i) in pvalueHeader" :key="i" class="align-middle table-header-bg">
                                {{ item }}
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(model, key) in mnvReportData.model" :key="key">
                            <b-td class="align-middle">{{ key }}</b-td>
                            <!-- <b-td v-for="(pValue, i) in model.pValues.slice(1)" :key="i">
                        {{ pValue }}
                    </b-td> -->
                            <b-td v-for="(pValue, i) in pvalueArray[key]" :key="i">
                                {{ pValue }}
                            </b-td>
                            <b-td class="align-middle">{{ model.R2 }}</b-td>
                            <b-td class="align-middle">{{ model.adjustedR2 }}</b-td>
                            <b-td class="align-middle">{{ model.stdDeviation }}</b-td>
                            <b-td class="align-middle">{{ model.meanBiasError }}</b-td>
                            <b-td class="align-middle">{{ model.cvrmse }}</b-td>
                            <b-td class="align-middle">{{ detailInfo.mnvMethod }}</b-td>
                            <b-td class="align-middle">{{ model.equation }}</b-td>
                            <b-td class="align-middle">
                                {{ mnvReportData.analysisOverview[key].report.details[0].accReduce.toFixed(1) ?? "-" }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div class="pageBreakDiv"></div>
            <div class="mnvSummary smallTitle">
                <i class="fa fas fa-check checkIcon" />
                <span class="mb-0 ml-1 mt-1">{{ "MNV 분석 정보" }}</span>
            </div>
            <div v-for="key in mainKey" :key="`${key}-analysis-result`" class="mnvAnalysisTable">
                <span v-if="mainKey.length > 1" class="mb-1" style="font-weight: bold;">
                    {{ `${key} 분석 모델 정보` }}
                </span>
                <b-table-simple fixed class="text-center tableCellBorder">
                    <b-thead>
                        <!-- <b-tr>
                        <b-td class="table-header-bg" colspan="2">{{ "분석 변수" }}</b-td>
                        <b-th>{{ mnvReportData.model[key].dependent }}</b-th>
                    </b-tr> -->
                        <b-tr>
                            <b-td class="table-header-bg analysisModelInfoHeader" colspan="2">{{ "분석 모델식" }}</b-td>
                            <b-th
                                :style="{ backgroundColor: '#FBE5D6' }"
                                :colspan="localPeriodName.length > 0 ? localPeriodName.length : 1"
                            >
                                {{ mnvReportData.model[key]?.equation }}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td rowspan="2" colspan="2" class="align-middle table-header-bg analysisModelInfoHeader">
                                {{ "개선률" }}
                            </b-td>
                            <b-td v-for="(item, i) in mnvReportData.analysisOverview[key].report.details" :key="i">{{
                                localPeriodName[i + 1]
                            }}</b-td>
                            <b-td>{{ "전체" }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th v-for="(item, i) in mnvReportData.analysisOverview[key].report.details" :key="i"
                                >{{ item.reduce }} %
                            </b-th>
                            <b-th>{{ mnvReportData.analysisOverview[key].report.reduce }} %</b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-table-simple class="text-center tableCellBorder" responsive>
                    <b-thead>
                        <b-tr>
                            <b-td
                                :colspan="
                                    Array.isArray(mnvReportData.model[key]?.independents) &&
                                    mnvReportData.model[key].independents.length > 0
                                        ? mnvReportData.model[key].independents.length
                                        : 1
                                "
                                class="table-header-bg"
                            >
                                {{ "P-Value" }}
                            </b-td>
                            <b-td
                                :colspan="
                                    Array.isArray(mnvReportData.model[key]?.independents) &&
                                    mnvReportData.model[key].independents.length > 0
                                        ? mnvReportData.model[key].independents.length
                                        : 1
                                "
                                class="table-header-bg"
                            >
                                {{ "VIF" }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td
                                v-for="(item, i) in mnvReportData.model[key].independents"
                                :key="`indep-${i}`"
                                class="table-header-bg"
                            >
                                {{ item }}
                            </b-td>

                            <b-td
                                v-for="(item, i) in mnvReportData.model[key].independents"
                                :key="`vif-${i}`"
                                class="table-header-bg"
                            >
                                {{ item }}
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-th v-for="(item, i) in mnvReportData.model[key].pValues.slice(1)" :key="`pvalue-${i}`">
                                {{ item }}
                            </b-th>
                            <b-th v-for="(item, i) in mnvReportData.model[key].vif.slice(1)" :key="`vif-${i}`">
                                {{ item }}
                            </b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-table-simple class="text-center tableCellBorder" responsive>
                    <b-thead>
                        <b-tr class="table-header-bg">
                            <!-- <b-td v-for="(item, i) in mnvReportData.model[key].independents" :key="i">
                            P-Value({{ item }})
                        </b-td> -->
                            <b-td class="align-middle" rowspan="2">{{ "모델 P-Value" }}</b-td>
                            <b-td class="align-middle" rowspan="2">{{ "모델 R2" }}</b-td>
                            <b-td class="align-middle" rowspan="2">{{ "수정된 모델 R2" }}</b-td>
                            <b-td class="align-middle" rowspan="2">{{ "모델의 표준 편차 (S)" }}</b-td>
                            <b-td class="align-middle" rowspan="2">{{ "모델의 평균 오차 (MBE)" }}</b-td>
                            <b-td class="align-middle" rowspan="2">{{ "모델의 변동 계수 CvRMSE" }}</b-td>
                            <b-td class="align-middle" rowspan="2">{{ "Method" }}</b-td>
                        </b-tr>
                        <!-- <b-tr class="table-header-bg">
                        <b-td v-for="(item, i) in mnvReportData.model[key].independents" :key="i">
                            {{ item }}
                        </b-td>
                    </b-tr> -->
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <!-- <b-th v-for="(item, i) in mnvReportData.model[key].pValues.slice(1)" :key="i">
                            {{ item }}
                        </b-th> -->
                            <b-th>{{ mnvReportData.model[key].modelPValue }}</b-th>
                            <b-th>{{ mnvReportData.model[key].R2 }}</b-th>
                            <b-th>{{ mnvReportData.model[key].adjustedR2 }}</b-th>
                            <b-th>{{ mnvReportData.model[key].stdDeviation }}</b-th>
                            <b-th>{{ mnvReportData.model[key].meanBiasError }}</b-th>
                            <b-th>{{ mnvReportData.model[key].cvrmse }}</b-th>
                            <b-th>{{ detailInfo.mnvMethod }}</b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </div>
        <div v-else class="emptyBox">
            <span class="detail-notification-icon">
                <i class="fas fa-exclamation-circle"></i>
            </span>
            <span class="detail-notification-text">표시할 데이터가 없습니다.</span>
        </div>
    </div>
</template>

<script>
import MnvChart from "../charts/MnvChart.vue";
import MnvResultSavingChartPanel from "../charts/MnvResultSavingChartPanel.vue";
import MnvResultImproveChartPanel from "../charts/MnvResultImproveChartPanel.vue";

export default {
    props: ["mnvReportData", "resultData", "detailInfo", "periodName", "reportMnvType", "activeTab"],
    components: {
        MnvChart,
        MnvResultSavingChartPanel,
        MnvResultImproveChartPanel,
    },
    data() {
        return {
            localPeriodName: [],
            chartRefArr: ["mnvChartRef", "mnvSavingChartRef", "mnvImproveChart"],
        };
    },
    computed: {
        slicedPvalues() {
            return this.mnvReportData.model.pValues.slice(1);
        },
        mainKey() {
            return this.resultData.variable.main.map((item) => item.key);
        },
        allDetails() {
            let array = [];
            if (this.mainKey.length > 1) {
                array = this.mainKey.map((key) => {
                    return this.mnvReportData.analysisOverview[key]?.report?.details || [];
                });
            }
            return array;
        },
        mergedDetails() {
            const maxLength = Math.max(...this.allDetails.map((details) => details.length));
            return Array.from({ length: maxLength }).map((_, index) => {
                const mergedItem = {};

                this.allDetails.forEach((details) => {
                    const item = details[index] || {};
                    Object.keys(item).forEach((key) => {
                        mergedItem[key] = (mergedItem[key] || 0) + (item[key] || 0);
                    });
                });

                return mergedItem;
            });
        },
        mappingPvalues() {
            const pValueObj = {};
            Object.values(this.mnvReportData.model).forEach((model) => {
                model.independents.forEach((independent, index) => {
                    if (!pValueObj[model.dependent]) {
                        pValueObj[model.dependent] = {};
                    }
                    pValueObj[model.dependent][independent] = model.pValues[index + 1];
                });
            });
            return pValueObj;
        },
        pvalueHeader() {
            const pSet = new Set();
            Object.values(this.mappingPvalues).forEach((item) => {
                Object.keys(item).forEach((key) => {
                    pSet.add(key);
                });
            });
            return Array.from(pSet);
        },
        pvalueArray() {
            let pObj = {};

            this.mainKey.forEach((key) => {
                this.pvalueHeader.forEach((header) => {
                    if (!pObj[key]) {
                        pObj[key] = [];
                    }
                    pObj[key].push(this.mappingPvalues[key][header] || "-");
                });
            });
            return pObj;
        },
    },
    watch: {
        //탭이 분석결과 탭일경우 차트를 라사이즈 함
        activeTab(tab) {
            if (tab === 1) {
                this.$nextTick(() => {
                    this.chartRefArr.forEach((refName) => {
                        if (this.$refs[refName] && typeof this.$refs[refName].resizeChart === "function") {
                            this.$refs[refName].resizeChart();
                        }
                    });
                    this.$emit("activeTabMnvReport");
                });
            }
        },

        periodName: {
            immediate: true,
            handler(newVal) {
                this.localPeriodName = [...newVal];
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        getConvertUint(key) {
            return this.mnvReportData.variable.main.find((item) => item.key == key)
                ? this.mnvReportData.variable.main.find((item) => item.key == key).factor.cnvtUnit
                : "-";
        },
        getCO2Unit(key) {
            return this.mnvReportData.variable.main.find((item) => item.key == key)
                ? this.mnvReportData.variable.main.find((item) => item.key == key).factor.CO2Unit
                : "-";
        },
    },
};
</script>

<style scoped>
.rowInvolveChart {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.mnvchart {
    width: 65%;
}
.table-header-bg {
    background-color: #eceff1;
}
.table-header-important {
    background-color: #e0e0e0;
}
.smallTitle {
    font-weight: bold;
    margin-bottom: 5px;
}
.pageBreakDiv {
    /* page-break-after: always; */
    height: 1.5rem;
    break-before: page;
}

.mnvsummaryTable {
    width: 33%;
}
.emptyBox {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 20rem;
    font-weight: bold;
    color: #444;
}
.detail-notification-icon {
    font-size: 48px;
}
.analysisModelInfoHeader {
    width: 230px;
}
.mnvSummary {
    margin-bottom: 10px;
}
</style>
