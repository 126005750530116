<template>
    <!-- 영향인자를 제외한 성분분석만 포함하도록 수정할 계획입니다. author 선구 -->
    <div v-if="resultData" class="stackChartContainer">
        <div ref="stackChart" style="width: 75%; height: 100%"></div>
        <div ref="pieChart" style="width: 25%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["resultData", "detailInfo", "hideInfoPanel"],
    data() {
        return {
            stackChart: null,
            pieChart: null,
            selectedYearIndex: 0,
        };
    },
    computed: {
        chartType() {
            return !this.isEmpty(this.detailInfo.dispType) && this.detailInfo.dispType !== "Area"
                ? this.detailInfo.dispType.toLowerCase()
                : "line";
        },
        labels() {
            return this.resultData?.values?.regDt || [];
        },
        ptMap() {
            const map = new Map(
                this.resultData.headers
                    .filter((header) => header.roleType == "Component")
                    .map((header) => [header.key, header.ptName])
            );
            return map;
        },
        seriesData() {
            return Array.from(this.ptMap.keys()).reduce((acc, ptIdx) => {
                if (ptIdx === "regDt") return acc;
                const ptName = this.ptMap.get(ptIdx);

                // const unit = this.detailInfo.variables.find((item) => item.ptName === ptName)?.unit || "-";
                acc.push({
                    name: ptName,
                    data: this.resultData.values[ptIdx],
                    unit: this.unitCalc(ptName),
                });
                return acc;
            }, []);
        },
        pieData() {
            return this.seriesData.map((series) => ({
                name: series.name,
                value: series.data[this.selectedYearIndex],
                unit: series.unit,
            }));
        },
    },
    created() {},
    mounted() {
        if (this.resultData) {
            this.initCharts();
            window.addEventListener("resize", this.resizeCharts);
        }
    },
    watch: {
        resultData() {
            this.resultData && this.initCharts();
        },
        hideInfoPanel() {
            if (this.stackChart) {
                this.stackChart.resize();
            }
            if (this.pieChart) {
                this.pieChart.resize();
            }
        },
    },
    methods: {
        unitCalc(ptName) {
            let finalunit = "";
            const unit = this.resultData.headers.find((item) => item.ptName === ptName).dispUnit;
            const smallUnit = this.resultData.headers.find((item) => item.ptName === ptName).unitSmallCode;
            if (unit) {
                finalunit = unit;
            } else if (!unit && smallUnit) {
                finalunit = smallUnit;
            } else {
                finalunit = "-";
            }
            return finalunit;
        },
        initCharts() {
            if (this.stackChart) this.stackChart.dispose();
            if (this.pieChart) this.pieChart.dispose();

            this.stackChart = echarts.init(this.$refs.stackChart);
            const stackOption = {
                grid: {
                    width: "85%",
                },
                legend: {
                    bottom: 5,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function(params) {
                        // 값 축약 함수
                        function formatValue(value) {
                            if (value >= 1e9) {
                                return Number.isInteger(value / 1e9)
                                    ? value / 1e9 + "G"
                                    : (value / 1e9).toFixed(1) + "G";
                            } else if (value >= 1e6) {
                                return Number.isInteger(value / 1e6)
                                    ? value / 1e6 + "M"
                                    : (value / 1e6).toFixed(1) + "M";
                            } else if (value >= 1e3) {
                                return Number.isInteger(value / 1e3)
                                    ? value / 1e3 + "K"
                                    : (value / 1e3).toFixed(1) + "K";
                            } else {
                                return value ? value : "-";
                            }
                        }

                        let result = `<div style="font-size: 12px; width:">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((param) => {
                            result += `<div style="display: flex; justify-content: space-between;">
                                                <div>
                                                    ${param.marker}
                                                    <span>
                                                        ${param.seriesName} 
                                                        (${param.data.unit})
                                                    </span>
                                                </div>
                                                <span style="font-weight: bold;">
                                                    ${formatValue(param.data.value)}
                                                </span>
                                        </div>`;
                        });

                        result += `</div></div>`;
                        return result;
                    },
                },
                xAxis: {
                    type: "category",
                    data: this.labels,
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        align: "right",
                        formatter: function(value) {
                            if (value >= 1e9) {
                                return Number.isInteger(value / 1e9)
                                    ? value / 1e9 + "G"
                                    : (value / 1e9).toFixed(1) + "G";
                            } else if (value >= 1e6) {
                                return Number.isInteger(value / 1e6)
                                    ? value / 1e6 + "M"
                                    : (value / 1e6).toFixed(1) + "M";
                            } else if (value >= 1e3) {
                                return Number.isInteger(value / 1e3)
                                    ? value / 1e3 + "K"
                                    : (value / 1e3).toFixed(1) + "K";
                            } else {
                                return value;
                            }
                        },
                    },
                },
                series: this.seriesData.map((series) => ({
                    name: series.name,
                    type: this.chartType,
                    stack: "Total",
                    smooth: true,
                    areaStyle: {},
                    data: series.data.map((value) => ({ value, unit: series.unit })),
                    emphasis: { focus: "series" },
                })),
            };

            this.stackChart.setOption(stackOption);

            this.pieChart = echarts.init(this.$refs.pieChart);
            this.updatePieChart();

            this.stackChart.on("updateAxisPointer", (event) => {
                if (!event.axesInfo[0]) return;
                const index = event.axesInfo[0].value;
                if (index !== this.selectedYearIndex) {
                    this.selectedYearIndex = index;
                    this.updatePieChart();
                }
            });
        },
        updatePieChart() {
            const pieOption = {
                tooltip: {
                    trigger: "item",
                    formatter: (params) => {
                        const unit = params.data?.unit || "-";
                        function formatValue(value) {
                            if (value >= 1e9) {
                                return Number.isInteger(value / 1e9)
                                    ? value / 1e9 + "G"
                                    : (value / 1e9).toFixed(1) + "G";
                            } else if (value >= 1e6) {
                                return Number.isInteger(value / 1e6)
                                    ? value / 1e6 + "M"
                                    : (value / 1e6).toFixed(1) + "M";
                            } else if (value >= 1e3) {
                                return Number.isInteger(value / 1e3)
                                    ? value / 1e3 + "K"
                                    : (value / 1e3).toFixed(1) + "K";
                            } else {
                                // return Number.isInteger(value) ? value.toString() : value.toFixed(3);
                                return value ? value : "-";
                            }
                        }
                        return `
        <div style="font-size: 12px;">
            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 4px;">
                ${params.marker} <span>${params.name} (${unit})</span>&nbsp;:&nbsp;
                <span style="font-weight: bold;">
                      ${formatValue(params.value)}
                </span>
                 (${params.percent}%)
            </div>
        </div>
    `;
                    },
                },
                series: [
                    {
                        name: "제품",
                        type: "pie",
                        radius: ["20%", "80%"],
                        center: ["50%", "50%"],
                        data: this.pieData.map((item) => ({ ...item, unit: item.unit })),
                        label: {
                            show: true,
                            position: "inside",
                            formatter: "{b}\n({d}%)",
                            fontSize: 12,
                            color: "#FFF",
                        },
                        labelLine: {
                            show: false,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            this.pieChart.setOption(pieOption);
        },
        resizeCharts() {
            this.stackChart && this.stackChart.resize();
            this.pieChart && this.pieChart.resize();
        },
    },
};
</script>

<style scoped>
.stackChartContainer {
    width: 100%;
    height: 100%;
    display: flex;
}
</style>
