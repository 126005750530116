<template>
    <div class="heatMapComponent">
        <div class="headerComponent">
            <span class="title">패턴 분석</span>
        </div>
        <div v-if="!emptyData" class="heatMapContainer">
            <div v-if="resultData.hourlyPattern" class="HourHeatMap">
                <trend-hour-heat-map-panel
                    :detailInfo="detailInfo"
                    :resultData="resultData"
                    :hideInfoPanel="hideInfoPanel"
                />
            </div>
            <div v-else-if="resultData.dailyPattern" class="DailyHeatMap">
                <trend-daily-heat-map-vue
                    :detailInfo="detailInfo"
                    :resultData="resultData"
                    :hideInfoPanel="hideInfoPanel"
                />
            </div>
        </div>
        <div v-else-if="emptyData" class="emptyBox">
            <span class="detail-notification-icon">
                <i class="fas fa-exclamation-circle"></i>
            </span>
            <span class="detail-notification-text">표시할 데이터가 없습니다.</span>
        </div>
    </div>
</template>

<script>
import TrendDailyHeatMapVue from "./TrendDailyHeatMap.vue";
import TrendHourHeatMapPanel from "./TrendHourHeatMapPanel.vue";

export default {
    props: ["resultData", "detailInfo", "hideInfoPanel"],
    components: {
        TrendHourHeatMapPanel,
        TrendDailyHeatMapVue,
    },
    data() {
        return {
            // emptyData: null,
        };
    },
    mounted() {},
    computed: {
        emptyData() {
            return !(
                (this.resultData &&
                    Array.isArray(this.resultData.hourlyPattern?.value) &&
                    this.resultData.hourlyPattern.value.length > 0) ||
                (Array.isArray(this.resultData.dailyPattern?.value) && this.resultData.dailyPattern.value.length > 0)
            );
        },
    },
    methods: {},
};
</script>

<style scoped>
.heatMapComponent {
    margin: 20px 0 0 0;
    box-sizing: border-box;
    width: 100%;
    height: 33%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.heatMapContainer {
    width: 100%;
    height: calc(100% - 35px);
    display: flex;
    justify-content: center;
}
.headerComponent {
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    height: 35px;
    flex-shrink: 0;
    font-weight: 600;
    background-color: #eceff1;
    border: 1px solid #eceff1;
}
.title {
    margin-left: 13px;
}
.HourHeatMap {
    width: 100%;
    height: 100%;
}
.DailyHeatMap {
    width: 100%;
    height: 100%;
}
.emptyBox {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
</style>
