<template>
    <div style="width: 100%; height: 100%;">
        <div ref="rltnScatterChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import ecStat from "echarts-stat";

export default {
    props: ["chartResize", "resultData", "detailInfo", "corrData", "hideInfoPanel"],
    data() {
        return {
            chart: null,
        };
    },
    computed: {
        targetInfo() {
            return this.detailInfo.variables.find((item) => item.roleType === "Main") ?? null;
        },
        unit() {
            return this.detailInfo.dispUnit;
        },

        // baseDataInfo() {
        //     const item = this.resultData.headers.find((header) => header.ptIdx === String(this.detailInfo.ptIdx));
        //     return item;
        // },
    },
    watch: {
        chartResize() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        resultData: {
            handler() {
                this.renderChart();
            },
            deep: true,
        },
        corrData: {
            handler() {
                this.renderChart();
            },
            deep: true,
        },
        hideInfoPanel() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
    mounted() {
        this.renderChart();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        renderChart() {
            if (!this.corrData || !this.resultData || !this.corrData.correlation) {
                console.warn("Invalid data. Cannot render chart.");
                return;
            }

            // Initialize chart
            this.chart = echarts.init(this.$refs.rltnScatterChart);

            // Calculate regression line
            const regression = ecStat.regression("linear", this.corrData.correlation);

            // Format regression points
            regression.points = regression.points.map((point) => [
                parseFloat(point[0].toFixed(3)),
                parseFloat(point[1].toFixed(3)),
            ]);

            // Define chart options
            const option = {
                grid: {
                    top: "5%",
                    height: "80%",
                    // bottom: "10%",
                },
                legend: {
                    show: false, // 범례 숨김
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "cross" },
                    formatter: (params) => {
                        if (Array.isArray(params)) {
                            const scatterPoint = params.find((p) => p.seriesName === "Scatter");
                            if (scatterPoint) {
                                const xValue = scatterPoint.data[0];
                                const yValue = scatterPoint.data[1];
                                return `
                    <div style="display: flex; flex-direction: column; font-size: 12px;">
                        <div style="display: flex; justify-content: space-between; margin-bottom: 4px;">
                            <span>${this.corrData.ptName}</span>&nbsp;&nbsp;
                            <span style="font-weight: bold;">${xValue.toFixed(1)}${
                                    this.corrData.unit === "celsius" ? "°C" : this.corrData.unit
                                }</span>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <span>${this.targetInfo.ptName}</span>&nbsp;&nbsp;
                            <span style="font-weight: bold;">${yValue?.toFixed(1) || 0} ${this.unit}</span>
                        </div>
                    </div>
                `;
                            }
                        }
                        return "";
                    },
                },

                xAxis: {
                    // name: this.corrData.ptName,
                    nameLocation: "middle",
                    nameGap: 25,
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                        },
                    },
                    axisLine: {
                        show: false, // X축 선 제거
                    },
                    axisTick: {
                        show: false, // X축의 틱 표시 제거
                    },
                },
                yAxis: {
                    // name: this.targetInfo.ptName,
                    splitLine: {
                        lineStyle: {
                            type: "dashed",
                        },
                    },

                    nameTextStyle: {
                        padding: [0, 0, 0, 120],
                    },
                    axisLine: {
                        show: false, // Y축 선 제거
                    },
                    axisTick: {
                        show: false, // X축의 틱 표시 제거
                    },
                },
                series: [
                    {
                        name: "Scatter",
                        type: "scatter",
                        data: this.corrData.correlation,
                        itemStyle: {
                            color: "#FF5733", // Scatter 점의 색상을 주황색으로 변경
                        },
                    },
                    {
                        name: "Regression Line",
                        type: "line",
                        data: regression.points,
                        showSymbol: false,
                        lineStyle: {
                            color: "#90CAF9", // 회귀선 색상을 파란색으로 설정 (주황색과 보색)
                            width: 2, // 선 두께
                        },
                        markPoint: {
                            label: {
                                formatter: () => `${regression.expression}`,
                                position: "left",
                                fontSize: 15,
                            },
                            data: [{ coord: regression.points[regression.points.length - 1] }],
                            itemStyle: {
                                color: "#90CAF9", // 마커 색상을 주황색으로 설정
                            },
                            symbolSize: 23,
                        },
                    },
                ],
            };

            this.chart.setOption(option);
            this.$nextTick(() => {
                if (this.chart) {
                    this.chart.resize();
                }
            });
        },
    },
    resizeChart() {
        if (this.chart) {
            this.chart.resize();
        }
    },
};
</script>
