<template>
    <div>
        <div class="baseTable">
            <b-table-simple class="text-center tableCellBorder">
                <b-thead class="sticky-header">
                    <b-tr>
                        <b-td rowspan="2" class="align-middle">구분</b-td>
                        <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">일자</b-td>
                        <b-td :colspan="resultData.variable.main.length" class="dependVar"> 종속변수 (측정) </b-td>
                        <b-td :colspan="resultData.variable.main.length" class="dependVar">종속변수 (환산)</b-td>
                        <b-td :colspan="independVar.length" :style="{ backgroundColor: '#ECEFF1' }">독립 변수</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td v-for="main in resultData.variable.main" :key="`${main.key}_original`" class="dependVar">
                            {{ main.key }} ({{ main.factor.targetUnit }})
                            <!-- 원래값 -->
                        </b-td>
                        <b-td v-for="main in resultData.variable.main" :key="`${main.key}_converted`" class="dependVar">
                            {{ main.key }} 환산 ({{ main.factor.cnvtUnit }})
                            <!-- 변환값 -->
                        </b-td>

                        <b-td
                            v-for="(item, i) in independVar"
                            :key="`independ_${i}`"
                            :style="{ backgroundColor: '#ECEFF1' }"
                        >
                            {{ item.key }} ({{ units(item.unit) }})
                        </b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="(item, i) in resultData.data.baseline.regDt"
                        :key="`row_${i}`"
                        :class="{ errorBackground: calcFalsyValue(resultData.data.baseline[mainKey]?.[i]) }"
                    >
                        <!-- 구분 (기간명) - 첫 번째 행에서만 rowspan 적용 -->
                        <b-td v-if="i === 0" :rowspan="resultData.data.baseline.regDt.length" class="align-middle">
                            {{ periodName }}
                        </b-td>

                        <!-- 날짜 -->
                        <b-td>{{ detailInfo.timeDsvn === "HOURBYDAY" ? item.split("T")[1] : item }}</b-td>

                        <!-- 종속변수 (측정) -->
                        <b-td v-for="(main, j) in resultData.variable.main" :key="`${main.key}_original_${j}`">
                            {{ resultData.data.baseline[mainInfoMap[main.key]?.key]?.[i] ?? "-" }}
                        </b-td>

                        <!-- 종속변수 (환산) -->
                        <b-td v-for="(main, j) in resultData.variable.main" :key="`${main.key}_converted_${j}`">
                            {{ resultData.data.baseline[mainInfoMap[main.key]?.converted]?.[i] ?? "-" }}
                        </b-td>

                        <!-- 독립 변수 -->
                        <b-td v-for="(independ, o) in independVar" :key="`independ_${o}`">
                            {{ resultData.data.baseline[independ.key]?.[i] ?? "-" }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: ["resultData", "detailInfo", "periodName"],
    computed: {
        ...mapState({
            envUnit: (state) => state.units,
        }),

        mainKey() {
            return this.resultData.variable.main[0]?.key ?? "";
        },

        mainInfoMap() {
            let map = {};
            for (let i = 0; i < this.resultData.variable.main.length; i++) {
                let item = this.resultData.variable.main[i];
                map[item.key] = {
                    key: item.key,
                    converted: `${item.key}_converted`,
                };
            }
            return map;
        },

        independVar() {
            let vars = [];
            for (let i = 0; i < this.resultData.variable.independ.length; i++) {
                let item = this.resultData.variable.independ[i];
                vars.push({
                    key: item.key,
                    unit: item.unitSmallCode,
                });
            }
            return vars;
        },
    },
    methods: {
        units(unitValue) {
            for (let i = 0; i < this.envUnit.length; i++) {
                if (this.envUnit[i].value === unitValue) {
                    return this.envUnit[i].text;
                }
            }
            return "Unknown Unit";
        },
        calcFalsyValue(value) {
            return value == undefined || value === 0 || value == null ? 1 : 0;
        },
    },
};
</script>

<style scoped>
.dependVar {
    background-color: #cfd8dc !important;
}
.errorBackground {
    background-color: #e57373;
    color: white;
}
.baseTable {
    /* max-height: 420px; */
    /* overflow-y: auto; */
    margin-bottom: 15px;
}
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #eceff1;
}

.converted-value {
    color: #666;
    font-size: 0.9em;
}
</style>
