<template>
    <b-table-simple bordered class="text-center tableCellBorder">
        <b-thead>
            <!-- Header row -->
            <b-tr>
                <b-td rowspan="2" colspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">
                    {{ "구분" }}
                </b-td>
                <b-td :colspan="baseLineKeys.length" :style="{ backgroundColor: '#ECEFF1' }">
                    {{ "종속변수 및 독립변수" }}
                </b-td>
                <b-td rowspan="2" class="align-middle" :style="{ backgroundColor: '#ECEFF1' }">
                    {{ "비고" }}
                </b-td>
            </b-tr>
            <b-tr>
                <!-- :style="keyIndex === 0 ? { backgroundColor: '#cfd8dc' } : { backgroundColor: '#ECEFF1' }" -->
                <b-td
                    v-for="(key, keyIndex) in baseLineKeys"
                    :key="'key' + keyIndex"
                    :style="
                        keyIndex < resultData.variable.main.length
                            ? { backgroundColor: '#cfd8dc' }
                            : { backgroundColor: '#ECEFF1' }
                    "
                >
                    {{ key.replace("_converted", "") }}
                </b-td>
            </b-tr>
        </b-thead>
        <b-tbody>
            <!-- Baseline data -->
            <b-tr>
                <b-td class="align-middle" rowspan="5" :style="{ backgroundColor: '#ECEFF1' }">
                    {{ "베이스라인 기간" }}
                </b-td>
            </b-tr>
            <b-tr v-for="(dataKey, dataKeyIndex) in Object.keys(baseLineData)" :key="'dataKey' + dataKeyIndex">
                <b-td :style="{ backgroundColor: '#ECEFF1' }">
                    {{
                        dataKey == "min"
                            ? "최소값"
                            : dataKey == "max"
                            ? "최대값"
                            : dataKey == "lower"
                            ? "이상치 하한"
                            : dataKey == "upper"
                            ? "이상치 상한"
                            : "-"
                    }}
                </b-td>
                <b-td v-for="(data, dataIndex) in baseLineData[dataKey]" :key="'data' + dataIndex">
                    {{ data }}
                </b-td>
                <b-td>{{ "-" }}</b-td>
            </b-tr>
            <!-- Report period data -->
            <!-- resultData.stats.report 배열의 제일 마지막(현재) 객체의 값을 표현 -->
            <b-tr>
                <b-td :style="{ backgroundColor: '#ECEFF1' }">{{ "보고 기간" }}</b-td>
                <b-td :style="{ backgroundColor: '#ECEFF1' }">{{ "평균" }}</b-td>
                <b-td v-for="(report, reportIndex) in baseLineKeys" :key="'report' + reportIndex">
                    {{ resultData.stats.report[resultData.stats.report.length - 1][report].mean ?? "-" }}
                </b-td>
                <b-td>{{ "-" }}</b-td>
            </b-tr>
            <!-- Evaluation row -->
            <b-tr>
                <b-td colspan="2" :style="{ backgroundColor: '#e3f2fd' }">유효성 평가</b-td>
                <!-- <b-td :style="{ backgroundColor: '#e3f2fd' }">0</b-td>
                <b-td :style="{ backgroundColor: '#e3f2fd' }">0</b-td>
                <b-td :style="{ backgroundColor: '#e3f2fd' }">0</b-td>
                <b-td :style="{ backgroundColor: '#e3f2fd' }">0</b-td> -->
                <b-td
                    v-for="(evaluation, evaluationIndex) in baseLineKeys"
                    :key="'evaluation' + evaluationIndex"
                    :style="{ backgroundColor: '#e3f2fd' }"
                >
                    {{ resultData.stats.report[resultData.stats.report.length - 1][evaluation].variance ?? "-" }}
                </b-td>
                <b-td :style="{ backgroundColor: '#e3f2fd' }">{{ "-" }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
export default {
    props: ["resultData"],
    components: {},
    data() {
        return {};
    },
    computed: {
        mainKeyAndAdjusted() {
            return [this.resultData.variable.main[0].key, `${this.resultData.variable.main[0].key}_adjusted`];
        },
        baseLineKeys() {
            // const keys = [];
            // const excludedKeys = this.mainKeyAndAdjusted;

            // // 모든 키 수집
            // this.resultData.stats.baseline.forEach((item) => {
            //     const key = Object.keys(item).filter((k) => !excludedKeys.includes(k));
            //     keys.push(...key);
            // });

            // // _converted로 끝나는 키를 앞쪽으로 정렬
            // const convertedKeys = keys.filter((k) => k.endsWith("_converted"));
            // const otherKeys = keys.filter((k) => !k.endsWith("_converted"));

            // // _converted 키를 앞에 붙여 최종 배열 반환
            // const sortedKeys = [...convertedKeys, ...otherKeys];
            // return sortedKeys ?? [];
            const keys = [];
            this.resultData.variable.main.forEach((item) => {
                keys.push(item.key);
            });
            this.resultData.variable.independ.forEach((item) => {
                keys.push(item.key);
            });
            return keys ?? [];
        },
        baseLineData() {
            const min = [];
            const max = [];
            const lower = [];
            const upper = [];

            this.baseLineKeys.forEach((key) => {
                const value = this.resultData.stats.baseline[0][key];
                if (value) {
                    const { min: minVal, max: maxVal, abnormalRange } = value;
                    min.push(minVal);
                    max.push(maxVal);
                    lower.push(abnormalRange.lower);
                    upper.push(abnormalRange.upper);
                }
            });

            return {
                min,
                max,
                lower,
                upper,
            };
        },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped></style>
