<template>
    <div style="width: 100%; margin: 10px 0;" class="analysis-data-container">
        <!-- 기존 테이블 -->
        <DataTable
            id="result-data-table"
            class="p-datatable data-table-container"
            :value="tableDatas"
            :scrollable="true"
            :scrollHeight="'500px'"
            showGridlines
        >
            <template #header>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div>
                        <span class="m-0 table-title">분석기간</span>
                    </div>
                    <div>
                        <button type="button" class="btn btn-sm btn-outline-secondary" @click="exportToCSV">
                            Export
                        </button>
                    </div>
                </div>
            </template>

            <Column
                field="regDt"
                header="날짜"
                :reorderableColumn="false"
                :style="{ width: '150px' }"
                :headerStyle="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }"
                :bodyStyle="{
                    'font-size': '13px',
                    'font-weight': 'bold',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }"
            ></Column>

            <Column
                v-for="(col, index) in dynamicColumns"
                :key="index"
                :field="col.key"
                :reorderableColumn="false"
                :headerStyle="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }"
                :bodyStyle="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }"
            >
                <template #header>
                    <div>
                        <div v-if="col.unit">{{ col.key }}({{ unitMap[col.unit] || "-" }})</div>
                    </div>
                </template>
            </Column>
        </DataTable>
        <div class="splitDiv"></div>
        <DataTable
            id="comparison-data-table"
            class="p-datatable data-table-container"
            :value="comparisonTableDatas"
            :scrollable="true"
            :scrollHeight="'500px'"
            showGridlines
        >
            <template #header>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div>
                        <span class="m-0 table-title">비교기간 ({{ compareTitle }})</span>
                    </div>
                    <div>
                        <button type="button" class="btn btn-sm btn-outline-secondary" @click="exportComparisonToCSV">
                            Export Comparison
                        </button>
                    </div>
                </div>
            </template>

            <Column
                field="regDt"
                header="날짜"
                :reorderableColumn="false"
                :style="{ width: '150px' }"
                :headerStyle="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }"
                :bodyStyle="{
                    'font-size': '13px',
                    'font-weight': 'bold',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }"
            ></Column>

            <Column
                v-for="(col, index) in comparisonDynamicColumns"
                :key="index"
                :field="col.key"
                :reorderableColumn="false"
                :headerStyle="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }"
                :bodyStyle="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }"
            >
                <template #header>
                    <div>
                        <div v-if="col.unit">{{ col.key }}({{ unitMap[col.unit] || "-" }})</div>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { mapState } from "vuex";
import moment from "moment";

import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        DataTable,
        Column,
    },
    props: {
        resultData: { type: Object, required: true },
        detailInfo: { type: Object },
    },
    data() {
        return {
            tableDatas: [],
            dynamicColumns: [],
            comparisonTableDatas: [],
            comparisonDynamicColumns: [],
            popupMessages,
            unitMap: {},
            localResultData: null,
        };
    },
    created() {},
    mounted() {},
    watch: {
        resultData: {
            immediate: true,
            deep: true,
            handler() {
                this.localResultData = this.resultData;
                this.processTableData();
                this.processComparisonData();
                this.unitNomalized();
            },
        },
    },
    computed: {
        ...mapState({
            units: (state) => state.unitCodes,
            compareType: (state) => state.commonCodes.compareType,
        }),
        compareTitle() {
            return this.compareType.find((item) => item.value === this.detailInfo.compareType).text;
        },
    },
    methods: {
        unitNomalized() {
            const tempUnitMap = {};
            Object.keys(this.units).forEach((bigUnit) => {
                this.units[bigUnit].forEach((smallUnit) => {
                    tempUnitMap[smallUnit.value] = smallUnit.text;
                });
            });
            this.unitMap = tempUnitMap;
        },
        timeConvert(reg) {
            const formatMap = {
                HOUR: "HH:mm",
                DAY: "YYYY-MM-DD",
                MONTH: "YYYY-MM-DD",
                YEAR: "YYYY-MM",
            };

            const format = formatMap[this.detailInfo.timeDsvn] || "YYYY-MM-DD HH:mm:ss";

            return moment(new Date(reg)).format(format);
        },
        findUnit(unitSmallCode) {
            for (const key of Object.keys(this.units)) {
                const foundUnit = this.units[key].find((item) => item.value === unitSmallCode);
                if (foundUnit) {
                    return foundUnit.text;
                }
            }
            return null;
        },
        calcUnit(header) {
            let finalUnit = "";
            const dispUnit = header.dispUnit;
            const unitSmallCode = header.unitSmallCode;
            if (dispUnit) {
                finalUnit = dispUnit;
            } else if (!dispUnit && unitSmallCode) {
                finalUnit = unitSmallCode;
            } else {
                finalUnit = "-";
            }

            // return this.findUnit(finalUnit);
            return finalUnit;
        },
        formatDateTime(dateTimeStr) {
            return dateTimeStr.replace("T", " ");
        },
        formatVariousTime(regDt) {
            const { compareType, timeDsvn } = this.detailInfo;

            if (!/^Avg/.test(compareType)) return this.formatDateTime(regDt);

            const formatMap = {
                QUARTER: () => `${regDt[0]}${regDt[1]}시${regDt[2]}${regDt[3]}분 평균`,
                HOUR: () => `${regDt}시 평균`,
                DAY: () => `${regDt[0]}${regDt[1]}월 ${regDt[2]}${regDt[3]}일 평균`,
                MONTH: () => `${regDt}월 평균`,
            };

            const formatter = formatMap[timeDsvn];
            return formatter ? formatter() : "";
        },
        processTableData() {
            const values = this.localResultData.values || {};
            const regDt = values.regDt || [];
            this.dynamicColumns = Object.keys(values)
                .filter((key) => key !== "regDt")
                .map((key) => {
                    const header = this.localResultData.headers.find((item) => item.key === key);
                    let unit = this.calcUnit(header);

                    return {
                        key,
                        unit,
                    };
                });

            this.tableDatas = regDt.map((date, index) => {
                const rowData = { regDt: this.formatDateTime(date) };
                this.dynamicColumns.forEach((col) => {
                    rowData[col.key] = values[col.key][index] || null;
                });
                return rowData;
            });
        },
        processComparisonData() {
            const comparison = this.localResultData.comparison || {};
            const regDt = comparison.regDt || [];

            const dynamicKeys = Object.keys(comparison)
                .filter((key) => comparison[key]?.value)
                .map((key) => {
                    const header = this.localResultData.headers.find((item) => item.key === key);
                    const unit = this.calcUnit(header);
                    return {
                        key,
                        unit,
                    };
                });

            this.comparisonDynamicColumns = dynamicKeys;

            this.comparisonTableDatas = regDt.map((date, index) => {
                const rowData = { regDt: this.formatVariousTime(date) };
                dynamicKeys.forEach((col) => {
                    const data = comparison[col.key] || {};
                    rowData[col.key] = data.value?.[index] || null;
                });
                return rowData;
            });
        },

        exportToCSV() {
            const escapeCSVValue = (value) => {
                if (typeof value === "string" && value.includes(",")) {
                    return `"${value.replace(/"/g, '""')}"`;
                }
                return value;
            };

            const csvData = [
                ["날짜", ...this.dynamicColumns.map((col) => escapeCSVValue(col.key))],
                ...this.tableDatas.map((row) => [
                    escapeCSVValue(row.regDt),
                    ...this.dynamicColumns.map((col) => escapeCSVValue(row[col.key])),
                ]),
            ];

            const csvContent = "\uFEFF" + csvData.map((e) => e.join(",")).join("\n");
            const fileName = `${this.detailInfo.modelName}.csv`;
            const link = document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent));
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // this.alertNoti(popupMessages.COMMON_EXCEL_DOWNLOAD_START_POPUP_MESSAGE);
        },
        exportComparisonToCSV() {
            const escapeCSVValue = (value) => {
                if (typeof value === "string" && value.includes(",")) {
                    return `"${value.replace(/"/g, '""')}"`;
                }
                return value;
            };

            const csvData = [
                ["날짜", ...this.comparisonDynamicColumns.map((col) => escapeCSVValue(col.key))],
                ...this.comparisonTableDatas.map((row) => [
                    escapeCSVValue(row.regDt),
                    ...this.comparisonDynamicColumns.map((col) => escapeCSVValue(row[col.key])),
                ]),
            ];

            const csvContent = "\uFEFF" + csvData.map((e) => e.join(",")).join("\n");
            const fileName = `${this.detailInfo.modelName}_comparison.csv`;
            const link = document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent));
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // this.alertNoti(popupMessages.COMMON_EXCEL_DOWNLOAD_START_POPUP_MESSAGE);
        },
    },
};
</script>

<style scoped>
.analysis-data-container {
    display: flex;
}
.data-table-container {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.table-title {
    font-weight: 600;
    font-size: 14px;
}
.splitDiv {
    width: 20px;
}
</style>
