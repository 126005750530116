<template>
    <div style="width: 100%; height: 100%;">
        <div ref="mnvChart" style="width: 100%; height: 100%;"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
// import chartData from "./MnvChartDummyData.json";

export default {
    props: ["mnvReportData", "periodName", "reportKey"],
    data() {
        return {
            chart: null,
            xAxisLabels: [],
            mnvChartData: [],
            minVal: null,
            maxVal: null,
            offsetBarChartData: [],
            symbolSize: 9,
        };
    },
    computed: {
        mainKey2() {
            return this.mnvReportData.variable.main.map((item) => item.key);
        },
        mainInfo() {
            return {
                original: this.mainKey,
                adjusted: `${this.mainKey}_adjusted`,
                converted: `${this.mainKey}_converted`,
            };
        },
        processBaselineData() {
            const result = this.mainKey2.reduce((sumArray, key) => {
                // const adjustedKey = `${key}_adjusted`;
                const adjustedKey = `${key}_converted`;

                if (this.mnvReportData.data.baseline[adjustedKey]) {
                    return sumArray.map((val, idx) => val + (this.mnvReportData.data.baseline[adjustedKey][idx] || 0));
                }
                return sumArray;
            }, new Array(this.mnvReportData.data.baseline[`${this.mainKey2[0]}`].length).fill(0));

            const predict = this.mainKey2.reduce((sumArray, key) => {
                const predictKey = `${key}_predict`;
                if (this.mnvReportData.data.baseline[predictKey]) {
                    return sumArray.map((val, idx) => val + (this.mnvReportData.data.baseline[predictKey][idx] || 0));
                }
                return sumArray;
            }, new Array(this.mnvReportData.data.baseline[`${this.mainKey2[0]}_predict`].length).fill(0));

            const obj = {
                regDt: this.mnvReportData.data.baseline.regDt,
                data: result,
                predict: predict,
            };

            return obj;
        },
        processReportData() {
            return this.mnvReportData.data.report.map((entry) => {
                const result = entry.regDt.map((_, index) => {
                    return this.mainKey2.reduce((sum, key) => {
                        // const adjustedKey = `${key}_adjusted`;
                        const adjustedKey = `${key}_converted`;

                        return sum + (entry[adjustedKey] ? entry[adjustedKey][index] : 0);
                    }, 0);
                });

                const predict = entry.regDt.map((_, index) => {
                    return this.mainKey2.reduce((sum, key) => {
                        const predictKey = `${key}_predict`;
                        return sum + (entry[predictKey] ? entry[predictKey][index] : 0);
                    }, 0);
                });

                return {
                    regDt: entry.regDt,
                    data: result,
                    predict: predict,
                };
            });
        },
    },
    watch: {
        mnvReportData: {
            immediate: true,
            handler() {
                this.generateChartData();
                this.prepareChart();
            },
        },
        periodName: {
            immediate: true,
            handler() {
                if (this.chart) {
                    this.prepareChart(); // 차트 데이터 갱신
                }
            },
        },
    },
    mounted() {
        this.generateChartData();
        this.prepareChart();
    },
    methods: {
        formatNumber(num) {
            if (Number.isInteger(num)) {
                return num;
            }

            const decimalPart = num.toString().split(".")[1] || "";

            if (decimalPart.length === 1) {
                return num;
            }

            return parseFloat(num.toFixed(2));
        },
        //상위컴포넌트에서 탭전환에 따른 차트 리사이즈를 위한 함수
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        formatYAxisValue(value) {
            if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + "k";
            } else {
                return value;
            }
        },
        generateIntervalLines() {
            const interval = [];
            // this.mnvReportData.data.report.forEach((report, reportIndex) => {
            this.processReportData.forEach((report, reportIndex) => {
                interval.push({
                    xAxis: report.regDt[report.regDt.length - 1],
                    name: `${this.periodName[reportIndex + 1]}`,
                    label: {
                        formatter: `${this.periodName[reportIndex + 1]}`,
                        position: "insideEndTop",
                        rotate: 0,
                        offset: [-30, 20],
                        align: "center",
                        verticalAlign: "middle",
                        color: "black",
                    },
                    lineStyle: {
                        type: "dashed",
                        color: "#333",
                    },
                });
            });
            return interval;
        },
        calcLower(measure, predict) {
            return measure < predict ? measure : predict;
        },
        calcUpper(measure, predict) {
            return measure < predict ? predict - measure : measure - predict;
        },
        generateChartData() {
            this.mnvChartData = [];
            this.offsetBarChartData = [];
            let minPredict = Infinity;
            let maxMeasure = -Infinity;

            // 베이스라인 데이터 처리
            // this.mnvReportData.data.baseline.regDt.forEach((date, i) => {
            this.processBaselineData.regDt.forEach((date, i) => {
                const measureValue = this.formatNumber(this.processBaselineData.data[i]);
                const predictValue = this.formatNumber(this.processBaselineData.predict[i]);

                //offset bar chart를 위한 데이터 생성
                this.offsetBarChartData.push({
                    date: date,
                    value: measureValue - predictValue,
                });

                // predict의 최솟값 갱신
                if (predictValue < minPredict) {
                    minPredict = predictValue;
                }

                // measure의 최댓값 갱신
                if (maxMeasure < predictValue) {
                    maxMeasure = predictValue;
                }
                if (maxMeasure < measureValue) {
                    maxMeasure = measureValue;
                }

                this.mnvChartData.push({
                    date: date,
                    //베이스라인기간의 데이터 또한 측정값을 기반으로 계산된 베이스라인으로한다.
                    value: measureValue,
                    predict: predictValue,
                    measure: measureValue,
                });
            });

            // 보고 데이터 처리
            // this.mnvReportData.data.report.forEach((report) => {
            this.processReportData.forEach((report, dataIndex) => {
                report.regDt.forEach((date, i) => {
                    const measureValue = this.formatNumber(report.data[i]);
                    //보고기간의 첫번째 인덱스의 데이터는 측정값을 predict로 사용한다. (부장님 지시)
                    const predictValue =
                        dataIndex === 0 && i === 0 ? measureValue : this.formatNumber(report.predict[i]);

                    //offset bar chart를 위한 데이터 생성
                    this.offsetBarChartData.push({
                        date: date,
                        value: measureValue - predictValue,
                    });
                    // predict의 최솟값 갱신
                    if (predictValue < minPredict) {
                        minPredict = predictValue;
                    }

                    // measure의 최댓값 갱신
                    if (maxMeasure < predictValue) {
                        maxMeasure = predictValue;
                    }
                    if (maxMeasure < measureValue) {
                        maxMeasure = measureValue;
                    }

                    this.mnvChartData.push({
                        date: date,
                        value: predictValue,
                        predictLower: this.calcLower(measureValue, predictValue),
                        predictUpper: this.calcUpper(measureValue, predictValue),
                        predict: predictValue,
                        measure: measureValue,
                    });
                });
            });
            this.minVal = minPredict;
            this.maxVal = maxMeasure;
        },
        prepareChart() {
            if (this.$refs.mnvChart) {
                this.renderChart();
            }
        },
        renderChart() {
            this.chart = echarts.init(this.$refs.mnvChart);
            const lastIndex = this.mnvChartData.findLastIndex((item) => !item.predictLower) + 1;

            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        animation: false,
                    },
                    formatter: function(params) {
                        // Extracting relevant data
                        const date = params[0]?.axisValue || "-";
                        const measure = params[2]?.data.measure || "-";
                        const predict = params[2]?.data.predict || "-";

                        // Constructing tooltip HTML
                        const tooltipText = `
        <div style="display: flex; flex-direction: column; font-size: 12px;">
            <strong style="font-weight= 400;">${date}</strong>

            <div style="display: flex; justify-content: space-between;">
                <div>
                <span style="display: inline-block; width: 10px; height: 10px; background-color: #5BAF32; border-radius: 50%; margin-right: 5px;"></span>
                측정 </div> &nbsp;&nbsp; <strong style="font-weight=400;">${measure}</strong>
            </div>
            <div style="display: flex; justify-content: space-between;">
                <div>
                <span style="display: inline-block; width: 10px; height: 10px; background-color: #5470C6; border-radius: 50%; margin-right: 5px;"></span>
                베이스라인 </div> &nbsp;&nbsp; <strong style="font-weight= 400;">${predict}</strong>
            </div>
        </div>
    `;

                        return tooltipText;
                    },
                },

                grid: [
                    {
                        left: "5%",
                        right: "1%",
                        top: "5%",
                        height: "15%",
                    },
                    {
                        left: "5%",
                        right: "1%",
                        top: "25%",
                        bottom: "3%",
                        height: "65%",
                    },
                ],
                xAxis: [
                    {
                        type: "category",
                        data: this.offsetBarChartData.map((item) => item.date),
                        gridIndex: 0,
                        axisLabel: { show: false },
                    },
                    {
                        type: "category",
                        data: this.mnvChartData.map((item) => item.date),
                        gridIndex: 1,
                        boundaryGap: false,
                        axisLabel: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        gridIndex: 0,
                        splitLine: { show: false },
                        axisLabel: {
                            show: false,
                        },
                    },
                    {
                        type: "value",
                        gridIndex: 1,
                        splitLine: { show: true },
                        axisLabel: {
                            formatter: this.formatYAxisValue,
                        },
                    },
                ],
                series: [
                    {
                        name: "Offset Bar Chart",
                        type: "bar",
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        data: this.offsetBarChartData.map((item) => ({
                            value: item.value,
                            itemStyle: { color: "#B0BEC5" },
                        })),

                        tooltip: {
                            show: false,
                        },
                    },
                    {
                        name: "L",
                        type: "line",
                        data: this.mnvChartData.map((item) => ({
                            value: item.predictLower,
                            itemStyle: { color: "#5BAF32" },
                        })),
                        symbol: "circle",
                        symbolSize: this.symbolSize,
                        // symbol: "none",
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        lineStyle: { opacity: 0 },
                        smooth: 0.4,
                        stack: "confidence-band",
                        // symbol: "none",
                        markPoint: null,
                    },
                    {
                        name: "U",
                        type: "line",
                        data: this.mnvChartData.map((item) => ({
                            value: item.predictUpper,
                            itemStyle: { color: "#5BAF32" },
                        })),
                        markPoint: null,
                        // symbol: "none",
                        symbolSize: this.symbolSize,
                        symbol: "circle",
                        // itemStyle: {
                        //     color: "#5BAF32",
                        // },
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        lineStyle: {
                            opacity: 1,
                            width: 2,
                            color: "#5BAF32",
                        },
                        smooth: 0.4,
                        areaStyle: {
                            color: "#5BAF32",
                        },
                        stack: "confidence-band",
                    },
                    {
                        name: "Target Data",
                        type: "line",
                        symbolSize: this.symbolSize,
                        symbol: "circle",
                        // symbol: "none",
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        lineStyle: {
                            width: 4,
                        },
                        data: this.mnvChartData.map((item) => ({
                            value: item.value,
                            measure: item.measure,
                            predict: item.predict,
                        })),
                        smooth: 0.4,
                        markLine: {
                            symbol: "none",
                            data: [
                                {
                                    label: {
                                        formatter: `${this.periodName[0]}`,
                                        position: "insideEndTop",
                                        rotate: 0,
                                        offset: [-39, 20],
                                        align: "center",
                                        verticalAlign: "middle",
                                        color: "black",
                                    },
                                    lineStyle: {
                                        type: "dashed",
                                        color: "#333",
                                    },
                                    xAxis: this.mnvChartData[lastIndex].date,
                                },

                                ...this.generateIntervalLines(),
                            ],
                        },

                        markArea: {
                            label: {
                                show: true,
                                fontSize: 12,
                            },
                            data: [
                                [
                                    {
                                        itemStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: "#E0E0E0" },
                                                { offset: 1, color: "#E0E0E0" + "00" },
                                            ]),
                                        },
                                        xAxis: this.mnvChartData[0].date,
                                    },
                                    { xAxis: this.mnvChartData[lastIndex].date },
                                ],
                                [
                                    {
                                        itemStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: "#F5F5F5" },
                                                { offset: 1, color: "#F5F5F5" + "00" },
                                            ]),
                                        },
                                        xAxis: this.mnvChartData[lastIndex + 1].date,
                                    },
                                    { xAxis: this.mnvChartData[this.mnvChartData.length - 1].date },
                                ],
                            ],
                        },
                    },
                ],
                visualMap: {
                    show: false,
                    dimension: 0,
                    pieces: [
                        {
                            gt: 0,
                            lte: lastIndex,
                            color: "#5470C6",
                        },
                        {
                            gt: lastIndex + 1,
                            lte: this.mnvChartData.length - 1,
                            color: "#5470C6",
                        },
                    ],
                },
            };

            this.chart.setOption(option);
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
    },
};
</script>
