<template>
    <div style="width: 100%; height: 100%;">
        <div ref="mnv-result-saving" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["mnvReportData", "reportKey"],
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        mergeDetails() {
            this.renderChart();
        },
    },
    computed: {
        mainKey() {
            const findMain = this.mnvReportData.variable.main.find((item) => item.key == this.reportKey);
            return findMain ? findMain.key : "";
        },
        mainInfo() {
            return {
                original: this.mainKey,
                adjusted: `${this.mainKey}_adjusted`,
                converted: `${this.mainKey}_converted`,
            };
        },
        mainKey2() {
            return this.mnvReportData.variable.main.map((item) => item.key);
        },
        mergeDetails() {
            // 기존의 각 변수별 details 배열을 가져옴
            const detailsArrays = this.mainKey2.map(
                (key) => this.mnvReportData.analysisOverview[key].report?.details || []
            );
            // 각 배열의 최대 길이 계산
            const maxLength = Math.max(...detailsArrays.map((details) => details.length));
            // 기존 데이터를 merge 하여 배열로 생성
            const merged = Array.from({ length: maxLength }, (_, index) => {
                return detailsArrays.reduce((merged, details) => {
                    const item = details[index] || {};
                    Object.keys(item).forEach((key) => {
                        merged[key] = this.formatNumber((merged[key] || 0) + item[key]);
                    });
                    return merged;
                }, {});
            });
            // 개선율 추이를 더욱 극적으로 보기위한 부장님의 요청으로 0값을 추가
            return [{ measure: 0, predict: 0 }, ...merged];
        },
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        formatNumber(num) {
            if (Number.isInteger(num)) {
                return num;
            }

            const decimalPart = num.toString().split(".")[1] || "";

            if (decimalPart.length === 1) {
                return num;
            }

            return parseFloat(num.toFixed(2));
        },
        //상위컴포넌트에서 탭전환에 따른 차트 리사이즈를 위한 함수
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        formatYAxisValue(value) {
            if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + "M";
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + "k";
            } else {
                return value;
            }
        },
        renderChart() {
            this.chart = echarts.init(this.$refs["mnv-result-saving"]);

            const option = {
                title: {
                    text: "개선율 추이",
                    left: "left",
                    textStyle: {
                        fontSize: 11,
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "line",
                    },
                },
                legend: {
                    data: ["측정", "베이스라인"],
                    bottom: "0%",
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: this.mergeDetails.map((_, i) => `${i}`),
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        formatter: this.formatYAxisValue,
                    },
                },
                series: [
                    {
                        name: "측정",
                        type: "line",
                        data: this.mergeDetails.map((item) => item.measure || 0),
                        smooth: 0.4,
                        color: "#5BAF32",
                        symbol: "circle",
                        symbolSize: 8,
                    },
                    {
                        name: "베이스라인",
                        type: "line",
                        data: this.mergeDetails.map((item) => item.predict || 0),
                        smooth: 0.4,
                        color: "#5470C6",
                        symbol: "circle",
                        symbolSize: 8,
                    },
                ],
            };

            this.chart.setOption(option);

            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
    },
};
</script>
