<template>
    <div class="statisticContainer">
        <div class="dataScatterBarComponent">
            <div class="headerComponent ">
                <span class="title">데이터 분포</span>
            </div>
            <div v-show="!emptyData" class="dataScatterBar" ref="barChart"></div>
            <div v-if="emptyData" class="emptyBox">
                <span class="detail-notification-icon">
                    <i class="fas fa-exclamation-circle"></i>
                </span>
                <span class="detail-notification-text">표시할 데이터가 없습니다.</span>
            </div>
        </div>
        <div class="analysisTable">
            <div class="headerComponent">
                <span class="title">통계지표</span>
            </div>
            <div v-if="!emptyData" class="firstTable">
                <b-table-simple bordered class="text-center table">
                    <!-- 첫 번째 테이블 섹션 -->
                    <b-tbody>
                        <b-tr class="tableSmallTitle">
                            <b-td class="tableTitleItem">
                                최소값
                            </b-td>
                            <b-td class="tableTitleItem">
                                최대값
                            </b-td>
                            <b-td class="tableTitleItem">
                                평균값
                            </b-td>
                            <b-td class="tableTitleItem">
                                표준편차
                            </b-td>
                        </b-tr>

                        <b-tr>
                            <b-th class="valueCell">{{ formattingNumber(resultData.statsDesc.min) || "- " }}</b-th>
                            <b-th class="valueCell">{{ formattingNumber(resultData.statsDesc.max) || "- " }}</b-th>
                            <b-th class="valueCell">{{ formattingNumber(resultData.statsDesc.mean) || "- " }}</b-th>
                            <b-th class="valueCell">{{ formattingNumber(resultData.statsDesc.stdDev) || "- " }}</b-th>
                        </b-tr>

                        <!-- 두 번째 테이블 섹션 -->

                        <b-tr class="tableSmallTitle">
                            <b-td class="tableTitleItem">
                                Q1
                            </b-td>
                            <b-td class="tableTitleItem">
                                Q2
                            </b-td>
                            <b-td class="tableTitleItem">
                                Q3
                            </b-td>
                            <b-td class="tableTitleItem">
                                분산
                            </b-td>
                        </b-tr>

                        <b-tr>
                            <b-th class="valueCell">{{
                                formattingNumber(resultData.statsDesc.quartile["Q1"]) || "- "
                            }}</b-th>
                            <b-th class="valueCell">{{
                                formattingNumber(resultData.statsDesc.quartile["Q2"]) || "- "
                            }}</b-th>
                            <b-th class="valueCell">{{
                                formattingNumber(resultData.statsDesc.quartile["Q3"]) || "- "
                            }}</b-th>
                            <b-th class="valueCell">{{ formattingNumber(resultData.statsDesc.variance) || "- " }}</b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div v-else-if="emptyData" class="emptyBox">
                <span class="detail-notification-icon">
                    <i class="fas fa-exclamation-circle"></i>
                </span>
                <span class="detail-notification-text">표시할 데이터가 없습니다.</span>
            </div>
        </div>

        <div class="dataOutlier">
            <div class="headerComponent">
                <span class="title">데이터 이상치 ({{ this.resultData.statsDesc.outliers.length || 0 }}건)</span>
            </div>
            <div v-if="!emptyData" class="outValTable">
                <b-table-simple bordered class="text-center">
                    <b-tbody>
                        <b-tr class="tableSmallTitle">
                            <b-td class="tableTitleItem">IQR</b-td>
                            <b-td class="tableTitleItem">이상값 상한</b-td>
                            <b-td class="tableTitleItem">이상값 하한</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="valueCell">{{ formattingNumber(resultData.statsDesc.IQR) || "-" }}</b-th>
                            <b-th class="valueCell">{{
                                formattingNumber(resultData.statsDesc.abnormalRange.upper) || "-"
                            }}</b-th>
                            <b-th class="valueCell">{{
                                formattingNumber(resultData.statsDesc.abnormalRange.lower) || "-"
                            }}</b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

                <div class="weird-table-container">
                    <b-table-simple bordered class="text-center align-middle">
                        <b-tbody>
                            <b-tr class="tableSmallTitle">
                                <b-td class="tableTitleItem">이상 위치</b-td>
                                <b-td class="tableTitleItem">이상값</b-td>
                            </b-tr>

                            <b-tr class="valueCell" v-for="(item, index) in resultData.statsDesc.outliers" :key="index">
                                <b-th>{{ item.regDt.replace("T", " ") }}</b-th>
                                <b-th>{{ formattingNumber(item.ptVal) }}</b-th>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </div>
            <div v-else-if="emptyData" class="emptyBox">
                <span class="detail-notification-icon">
                    <i class="fas fa-exclamation-circle"></i>
                </span>
                <span class="detail-notification-text">표시할 데이터가 없습니다.</span>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["resultData", "hideInfoPanel"],
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        resultData: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    if (!this.emptyData) {
                        this.initBarChart();
                    } else {
                        this.chart.dispose();
                    }
                });
            },
        },
        hideInfoPanel() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
    computed: {
        emptyData() {
            return this.resultData && this.resultData.statsDesc.frequences
                ? this.resultData.statsDesc.frequences.data.every((item) => item === 0)
                : true;
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.resultData) {
                this.initBarChart();
            }
        });
        window.addEventListener("resize", this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chart) {
            this.chart.dispose();
        }
    },
    methods: {
        formattingNumber(val) {
            return Number(val).toLocaleString();
        },
        initBarChart() {
            if (this.emptyData) return;
            const xAxisLabels = this.resultData.statsDesc.frequences.range.map(
                (range) => `${range[0].toFixed(0)}~${range[1].toFixed(0)}`
            );
            const seriesData = this.resultData.statsDesc.frequences.data;

            this.chart = echarts.init(this.$refs.barChart);
            const option = {
                grid: {
                    left: "15%",
                    right: "10%",
                    top: "12%",
                    bottom: "10%",
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function(params) {
                        return `<div style="font-size: 14px;">
                                    ${params[0].marker}
                                <span> ${params[0].axisValueLabel}</span> : 
                                    <span style="font-weight: bold;">${params[0].value}</span>
                                </div> `;
                    },
                },
                xAxis: {
                    type: "category",
                    data: xAxisLabels,
                    axisLabel: {
                        rotate: 45,
                    },
                    show: false,
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        type: "bar",
                        data: seriesData,
                        itemStyle: {
                            color: "#73C9E6",
                        },
                    },
                ],
            };
            this.chart.setOption(option);
            this.resizeChart();
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.tableTitleItem {
    width: 25%;
    color: #555;
}

.tableSmallTitle {
    height: 1rem;

    background-color: #f5f5f5;
    font-weight: bold;
}
.statisticContainer {
    margin: 20px 0;
    width: 100%;
    height: 16%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.headerComponent {
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    height: 35px;
    font-weight: 600;
    flex-shrink: 0;
    background-color: #eceff1;
    border: 1px solid #eceff1;
}

.title {
    margin-left: 13px;
}

.firstTable {
    height: 100%;
}
.outValTable {
    height: 100%;
}
.dataScatterBarComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 32%;
    /* height: 100%; */
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.analysisTable {
    width: 32%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.dataScatterBar {
    width: 100%;
    height: 100%;
}

.dataOutlier {
    width: 32%;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.firstTable .text-center.table {
    height: 100%;
}
.emptyBox {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.weird-table-container {
    width: 100%;
    max-height: 40%;
    overflow-y: auto;
    margin-top: 5px;
}
.valueCell {
    color: #222;
    background-color: "white";
    vertical-align: middle;
}
</style>
