<template>
    <div class="view-container">
        <div v-if="detailInfo" class="detail-header">
            <div class="col detail-title">
                <!-- <span class="title">{{ vie/wTitle }}</span> -->
                <span class="badge title1">{{ detailInfo.modelName ?? "-" }}</span>
                <span class="badge title2"> {{ detailInfo.category ?? "-" }} </span>

                <span class="badge others"> {{ targetPtName ?? "-" }} </span>

                <span class="badge others">
                    {{ `연관 데이터 수 (${detailInfo.variables?.length ?? "-"}개)` }}
                </span>
            </div>
        </div>
        <div v-if="detailInfo" class="model-info">
            <div style="flex: 2; padding: 0.5rem;">
                <label for="target">{{ "분석 대상" }}</label>
                <input :value="`${targetPtName}`" class="form-control" type="text" id="target" disabled />
            </div>
            <div style="flex: 1; padding: 0.5rem;">
                <label for="analysisMethod">{{ "분석 방법" }}</label>
                <select class="form-control" id="analysisMethod" v-model="detailInfo.analysisMethod" disabled>
                    <option :value="null">{{ "분석 방법 선택" }}</option>
                    <option
                        v-for="method in $store.state.commonCodes.analysisMethod"
                        :key="method.value"
                        :value="method.value"
                    >
                        {{ method.text }}
                    </option>
                </select>
            </div>
            <div v-if="detailInfo.analysisMethod == 'MNV'" style="flex: 1; padding: 0.5rem;">
                <label for="analysisMethod">{{ "MNV 방법" }}</label>
                <select class="form-control" id="analysisMethod" v-model="detailInfo.mnvMethod">
                    <option :value="null">{{ "MNV 방법 선택" }}</option>
                    <option v-for="type in $store.state.commonCodes.MnvMethod" :key="type.value" :value="type.value">
                        {{ type.text }}
                    </option>
                </select>
            </div>
            <div style="flex: 1; padding: 0.5rem;">
                <label for="dispType">{{ "표시 방법" }}</label>
                <select
                    v-model="detailInfo.dispType"
                    class="form-control"
                    name="dispType"
                    :disabled="detailInfo.analysisMethod == 'MNV' || detailInfo.analysisMethod == 'Goal'"
                >
                    <option :value="null">{{ "표시 방법 선택" }}</option>
                    <option v-for="type in $store.state.commonCodes.dispType" :key="type.value" :value="type.value">
                        {{ type.text }}
                    </option>
                </select>
            </div>
            <div style="flex: 1; padding: 0.5rem;">
                <label for="dispUnit">{{ "단위 환산" }}</label>
                <select v-model="detailInfo.dispUnit" class="form-control" name="dispUnit">
                    <option :value="null">{{ "단위 환산 선택" }}</option>
                    <option v-for="type in $store.state.commonCodes.dispUnit" :key="type.value" :value="type.value">
                        {{ type.text }}
                    </option>
                </select>
            </div>
            <div style="flex: 1; padding: 0.5rem;">
                <label for="">{{ "데이터 유형" }}</label>
                <select class="form-control" id="timeDsvn" v-model="detailInfo.timeDsvn">
                    <option :value="null">{{ "데이터 유형 선택" }}</option>
                    <option v-for="dateType in filterdTimeDsvn" :key="dateType.value" :value="dateType.value">
                        {{ dateType.text }}
                    </option>
                </select>
            </div>
            <div style="flex: 2; padding: 0.5rem;">
                <label for="">{{ calendarName[0] }}</label>
                <!-- :disabledCalendar="!isEditMode" -->
                <!-- class="col-9 pr-0" -->

                <calendar-component
                    start="true"
                    v-model="baseDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    @input="getBaseDayTime"
                    placeholder="기본 기간 선택"
                    :detailInfo="detailInfo"
                    :rangeBooleanSuper="rangeBooleanlocal"
                />
            </div>
            <div style="flex: 1; padding: 0.5rem;">
                <label for="compareType">{{ "비교 방법" }}</label>
                <select
                    class="form-control"
                    id="compareType"
                    :class="{ 'disabled-select': !detailInfo.timeDsvn }"
                    v-model="detailInfo.compareType"
                    :disabled="!detailInfo.timeDsvn || detailInfo.analysisMethod === 'Goal'"
                >
                    <option :value="null">{{ "비교 없음" }}</option>
                    <option v-for="comp in compareTypeArr" :key="comp.value" :value="comp.value">
                        {{ comp.text }}
                    </option>
                </select>
            </div>
            <div style="flex: 2; padding: 0.5rem;">
                <label for="">{{ calendarName[1] }}</label>
                <calendar-component
                    end="true"
                    v-model="compareDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    :baseDayTime="baseDayTime"
                    :compareType="detailInfo.compareType"
                    @input="getCompareDayTime"
                    placeholder="비교 기간 선택"
                    :detailInfo="detailInfo"
                    :rangeBooleanSuper="rangeBooleanlocal"
                />
            </div>
            <div style="flex: 2; padding: 0.5rem;">
                <div style="display: flex; flex: 1; margin-bottom: 0.4rem;">
                    <button
                        class="btn btn-sm btn-primary button-shadow"
                        type="button"
                        style="flex: 1;"
                        @click="getAnalysisResultData"
                    >
                        {{ "분석 실행" }}
                    </button>
                </div>
                <div style="display: flex; flex: 1;">
                    <button
                        v-b-tooltip.hover.bottom
                        :title="$t('PDF 다운로드')"
                        class="btn btn-sm btn-secondary button-shadow"
                        type="button"
                        style="flex: 1; margin-right: 0.2rem;"
                        @click="exportToPDF"
                    >
                        {{ "보고서" }}
                    </button>
                    <button
                        v-b-tooltip.hover.bottom
                        :title="$t('분석 설정 화면 바로가기')"
                        class="btn btn-sm btn-secondary button-shadow"
                        type="button"
                        style="flex: 1; margin:0px 0.2rem 0px 0.2rem;"
                        @click="goToModelSettingPage"
                    >
                        {{ "설정" }}
                    </button>
                    <!-- <button
                        v-b-tooltip.hover
                        :title="$t('분석 초기화')"
                        class="btn btn-sm btn-secondary button-shadow"
                        type="button"
                        style="flex: 1; margin-left: 0.2rem;"
                        @click="clearResultData"
                    >
                        {{ "초기화" }}
                    </button> -->
                    <button
                        v-b-tooltip.hover.bottom
                        :title="$t('분석 데이터 보기')"
                        class="btn btn-sm btn-secondary button-shadow"
                        type="button"
                        style="flex: 1; margin-left: 0.2rem;"
                        @click="() => $refs['anaysisDataModal'].show()"
                    >
                        {{ "데이터" }}
                    </button>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: calc(100% - 9rem);">
            <div block v-b-toggle.analysisResult class="accordion-title">
                <span>{{ "분석 결과" }}</span>
                <span>
                    <span
                        v-if="detailInfo.analysisMethod !== 'MNV'"
                        style="cursor: pointer; margin-right: 1rem;"
                        @click="handleInfoPanel($event)"
                        v-b-tooltip.hover
                        :title="$t(`${hideInfoPanel ? '분석 개요 가리기' : '분석 개요 보기'}`)"
                    >
                        <i :class="hideInfoPanel ? 'fas fa-indent' : 'fas fa-outdent'" aria-hidden="true"></i>
                    </span>
                    <span
                        v-if="
                            detailInfo.analysisMethod === 'Trend' ||
                                detailInfo.analysisMethod === 'Diff' ||
                                detailInfo.analysisMethod === 'Comp'
                        "
                        class="status-icon pr-3"
                        style="cursor: pointer"
                        @click="showStatisticModal($event)"
                        v-b-tooltip.hover
                        :title="$t('통계 항목 표시 제어')"
                    >
                        <i class="fas fa-ellipsis-h" aria-hidden="true"></i>
                    </span>

                    <span class="when-opened">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </span>
                    <span class="when-closed">
                        <i class="fa fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </span>
            </div>
            <b-collapse
                visible
                ref="analysisResult"
                id="analysisResult"
                class="collapse-body result-itemview"
                accordion="analysisResult"
            >
                <div v-if="isLoading" class="analysisSpinner">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
                <div v-else-if="!isEmpty(resultData)" ref="result" class="row m-0">
                    <div
                        :class="
                            detailInfo.analysisMethod === 'MNV' ? 'col-12' : hideInfoPanel ? 'col-9 pl-0' : 'col-12'
                        "
                    >
                        <ResultChartPanel
                            :detailInfo="detailInfo"
                            :analysisMethod="analysisMethod"
                            :resultData="resultData"
                            :calendarList="calendarList"
                            :switchValues="switchValues"
                            :hideInfoPanel="hideInfoPanel"
                        />
                    </div>
                    <div class="col-3 pr-0" v-if="detailInfo.analysisMethod !== 'MNV' && hideInfoPanel">
                        <ResultInfoPanel
                            :detailInfo="detailInfo"
                            :calendarList="calendarList"
                            @detail="showAnalysisHistory"
                            @getEnergyCalendarList="getEnergyCalendarList"
                        />
                    </div>
                </div>
                <div v-else class="list-placeholder">
                    <span class="list-notification-icon">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    <span class="list-notification-text">
                        {{ "분석을 실행해주세요." }}
                    </span>
                </div>
            </b-collapse>
        </div>
        <b-modal ref="analysis-history" size="lg" centered @hidden="() => $refs['analysis-history'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "분석 기록 관리" }}</h5>
            </template>
            <HistoryMgmtModalPanel :detailInfo="detailInfo" :resultData="resultData" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1"
                        @click="() => $refs['analysis-history'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <b-modal id="statistic-modal" ref="statisticModal" title="통계 항목 표시 제어">
            <template #modal-header>
                <h5 class="mb-0">통계 항목 표시 제어.</h5>
            </template>
            <div>
                <div
                    v-for="(label, key) in switchLabels"
                    :key="key"
                    class="d-flex align-items-center justify-content-between my-2"
                >
                    <label :for="'switch-' + key">{{ label }}</label>
                    <b-form-checkbox v-model="switchValues[key]" :id="'switch-' + key" switch></b-form-checkbox>
                </div>
            </div>

            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        type="button"
                        class="btn btn-secondary ctrl-modal-btn"
                        @click="() => $refs.statisticModal.hide()"
                        style="flex: 1; text-align: center;"
                    >
                        닫기
                    </button>
                </div>
            </template>
        </b-modal>
        <b-modal ref="anaysisDataModal" size="xl" title="분석 데이터" hide-footer class="analysisDataTable" centered>
            <analysis-data-table :resultData="resultData" :detailInfo="detailInfo"></analysis-data-table>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import ResultChartPanel from "./panels/ResultChartPanel.vue";
import ResultInfoPanel from "./panels/ResultInfoPanel.vue";
import HistoryMgmtModalPanel from "./panels/HistoryMgmtModalPanel.vue";
import CalendarComponent from "@src/views/playground/CalendarComponent.vue";
import AnalysisDataTable from "./tables/AnalysisDataTable.vue";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { mapState } from "vuex";

export default {
    props: [],
    components: {
        CalendarComponent,
        ResultChartPanel,
        ResultInfoPanel,
        HistoryMgmtModalPanel,
        AnalysisDataTable,
    },
    data() {
        return {
            isLoading: false,
            detailInfo: null,
            targetPtName: "",
            filterdTimeDsvn: this.timeDsvn,

            // calendar property
            baseDayTime: [],
            compareDayTime: [],
            rangeBooleanlocal: false,
            compareTypeArr: [],

            // result property
            resultData: [],
            showReltnGoal: false,
            analysisMethod: null,

            calendarList: [],
            switchValues: {
                mean: false, // 평균
                quartile: false, // 사분위수
                outlier: false, // 이상
                event: false, // 이벤트
            },
            switchLabels: {
                mean: "평균",
                quartile: "사분위수",
                outlier: "이상",
                event: "이벤트",
            },
            hideInfoPanel: false,
            isLoadingPdf: false,
            firstLoad: true,
        };
    },
    computed: {
        ...mapState({
            pointList: (state) => state.pointList,
            timeDsvnStore: (state) => state.commonCodes.timeDsvn,
            compareType: (state) => state.commonCodes.compareType,
        }),
        calendarName() {
            if (this.detailInfo.analysisMethod === "MNV") {
                return ["베이스라인 기간", "보고 기간"];
            } else {
                return ["분석 기간", "비교 기간"];
            }
        },
    },

    watch: {
        "$route.params.id": {
            immediate: true,
            async handler(newVal) {
                this.resultData = [];
                await this.loadData(newVal);
                this.setDayTime(this.detailInfo);
                this.getAnalysisResultData();
                this.firstLoad = false;
            },
        },

        "detailInfo.analysisMethod": {
            deep: true,
            handler(method) {
                if (method) {
                    this.switchVariables(method);
                }
                this.initializeSwitchValues();

                if (method === "MNV") {
                    this.detailInfo.compareType = "Baseline";
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return item.options.type === "Type2";
                    });
                } else if (method === "Goal") {
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return ["DAYBYMONTH", "MONTHBYYEAR"].includes(item.value);
                    });
                } else {
                    this.filterdTimeDsvn = this.timeDsvnStore.filter((item) => {
                        return item.options.type === "Type1";
                    });
                }
            },
        },
        "switchValues.mean": function(newVal) {
            if (newVal) {
                this.switchValues.quartile = false;
            }
        },
        "switchValues.quartile": function(newVal) {
            if (newVal) {
                this.switchValues.mean = false;
            }
        },
        "detailInfo.fromDate": "autoRunAnalysis",
        "detailInfo.fromDateComp": "autoRunAnalysis",
        "detailInfo.dispType": "autoRunAnalysis",
        "detailInfo.dispUnit": "autoRunAnalysis",
        "detailInfo.timeDsvn": "autoRunAnalysis",
        "detailInfo.compareType": "autoRunAnalysis",
    },
    created() {},
    mounted() {},
    methods: {
        autoRunAnalysis() {
            if (!this.firstLoad) {
                this.getAnalysisResultData();
            }
        },
        initializeSwitchValues() {
            this.switchValues = {
                mean: true, // 평균
                quartile: false, // 사분위수
                outlier: true, // 이상
                event: true, // 이벤트
            };
        },

        async loadData(modelIdx) {
            try {
                let result = await backEndApi.analysisModel.getAnalysisModel(modelIdx);
                if (result.status == 200) {
                    this.detailInfo = result.data;

                    // const find = this.pointList.find((point) => point.ptIdx == this.detailInfo.ptIdx);
                    // const rangeBoolean =
                    //     ["MONTH", "DAY", "YEAR"].includes(this.detailInfo.timeDsvn) &&
                    //     this.detailInfo.analysisMethod !== "Goal";

                    // const rangeBoolean =
                    //     (["MONTH", "DAY", "YEAR", "HOURBYDAY", "DAYBYMONTH", "MONTHBYYEAR"].includes(
                    //         this.detailInfo.timeDsvn
                    //     ) &&
                    //         this.detailInfo.timeDsvn.analysisMethod !== "Goal") ||
                    //     this.detailInfo.timeDsvn.analysisMethod === "MNV";

                    // if (!this.isEmpty(find)) this.targetPtName = find.ptName;
                    // this.changeRange(rangeBoolean);
                    // this.setDayTime(this.detailInfo);
                    this.customCompareType();
                    this.getTargetName();
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        getTargetName() {
            if (this.detailInfo.analysisMethod !== "MNV") {
                let find = this.detailInfo.variables.find((item) => item.roleType === "Main");
                if (!this.isEmpty(find)) this.targetPtName = find.name;
                else this.targetPtName = "-";
            } else {
                let filterVariables = this.detailInfo.variables.filter((item) => item.roleType === "Depend");
                filterVariables.forEach((item, index) => {
                    this.targetPtName += item.name;
                    if (index < filterVariables.length - 1) this.targetPtName += ", ";
                });
            }
        },
        async getAnalysisResultData() {
            let analysisMethod = this.detailInfo.analysisMethod;

            const searchInfo = {
                ptIdx: this.detailInfo.ptIdx,
                timeDsvn: this.detailInfo.timeDsvn,
                // fromDate: this.rangeBooleanlocal ? this.baseDayTime[0] : this.baseDayTime,
                // toDate: this.rangeBooleanlocal ? this.baseDayTime[1] : this.baseDayTime,
                fromDate: this.detailInfo.fromDate,
                toDate: this.detailInfo.toDate,
                variables: [],
                compareType: this.detailInfo.compareType,
                datasetMode: "Divided",
                dispUnit: this.detailInfo.dispUnit,
            };

            this.detailInfo.variables.forEach((pt) => {
                searchInfo.variables.push(pt);
            });

            // fromDateComp 설정
            if (this.detailInfo.compareType === "User") {
                searchInfo.fromDateComp = this.detailInfo.fromDateComp;
            } else {
                searchInfo.fromDateComp = null;
            }

            // toDateComp 설정
            if (this.detailInfo.compareType === "User") {
                if (this.detailInfo.toDateComp) {
                    searchInfo.toDateComp = this.detailInfo.toDateComp;
                } else {
                    searchInfo.toDateComp = this.detailInfo.fromDateComp;
                }
            } else {
                searchInfo.toDateComp = null;
            }

            // 에너지 캘린더 목록 조회
            if (analysisMethod == "Trend" || analysisMethod == "Diff" || analysisMethod == "Comp")
                await this.getEnergyCalendarList(searchInfo.fromDate, searchInfo.toDate);

            if (analysisMethod == "Trend") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.getAnalyzeTrend(searchInfo); // 트렌드 분석 API 호출 함수
            } else if (analysisMethod == "Diff") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.analyzeComparison(searchInfo);
            } else if (analysisMethod == "Comp") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.analyzeComponent(searchInfo);
            } else if (analysisMethod == "Reltn") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeCorrelation(searchInfo);
            } else if (analysisMethod == "Goal") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeGoal(searchInfo);
            } else if (analysisMethod == "Cost") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "MNV") {
                const mvnSearchInfo2 = {
                    startOfYear: 1,
                    ptIdx: this.detailInfo.ptIdx,
                    timeDsvn: this.detailInfo.timeDsvn,
                    fromDate: this.detailInfo.fromDate,
                    toDate: this.detailInfo.toDate,
                    variables: [],
                    compareType: this.detailInfo.compareType,
                    fromDateComp: this.detailInfo.fromDateComp,
                    toDateComp: this.detailInfo.toDateComp,
                    datasetMode: "Divided",
                    dispUnit: this.detailInfo.dispUnit,
                    adjustData: null,
                    userData: {
                        baseline: this.detailInfo.userData?.baseline,
                        report: this.detailInfo.userData?.report,
                    },
                };
                this.detailInfo.variables.forEach((pt) => {
                    mvnSearchInfo2.variables.push(pt);
                });

                // if (Object.keys(this.adjustData).length > 0) {
                //     mvnSearchInfo2.adjustData = this.adjustData;
                // }
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                await this.analyzeMNV(mvnSearchInfo2);
            } else {
                this.resultData = [];
                this.alertWarning("분석 결과를 조회할 수 없습니다.");
            }
        },
        async getAnalyzeTrend(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.getAnalyzeTrend(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeComparison(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeComparison(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeComponent(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeComponent(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeCorrelation(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeCorrelation(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeGoal(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeGoal(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    // this.resultData = [];
                    // this.alertWarning(
                    //     `${result.data.message}`,
                    //     "",
                    //     `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    // );
                    this.resultData = result.data.detail;
                    console.log(this.resultData);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async analyzeMNV(searchInfo) {
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeMNV(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.resultData = [];
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        // 에너지 캘린더 목록 조회
        async getEnergyCalendarList(fromDate, toDate) {
            try {
                let result = await backEndApi.analysisModel.getEnergyCalendarList(fromDate, toDate);
                if (result.status == 200) {
                    this.calendarList = result.data;
                    if (!this.isEmpty(this.calendarList)) {
                        this.calendarList.forEach((calendar) => {
                            calendar.regDate = moment(calendar.regDate).format("YYYY-MM-DD HH:mm:ss");
                            calendar.modDate = moment(calendar.modDate).format("YYYY-MM-DD HH:mm:ss");
                        });
                    }
                } else this.calendarList = [];
            } catch (e) {
                console.error(e);
            }
        },
        // 에너지캘린더 조회 (param: id)
        async getEnergyCalendar() {},
        // 에너지캘린더 저장 (param: calendar)
        async saveEnergyCalendar() {},
        // 에너지캘린더 삭제 (param: id)
        async deleteEnergyCalendar() {},
        goToModelSettingPage() {
            this.$router.push({
                name: "DataAnalysis",
                params: { from: "DataAnalysisView", modelIdx: this.detailInfo.modelIdx },
            });
        },
        showAnalysisHistory() {
            this.$refs["analysis-history"].show();
        },
        clearResultData() {
            this.resultData = [];
        },
        exportToPDF() {
            this.isLoadingPdf = true;
            const resultElement = this.$refs.result;
            const TrendMethod = this.detailInfo.analysisMethod === "Trend";

            const elementsWithBoxShadow = resultElement.querySelectorAll("*");
            const originalStyles = Array.from(elementsWithBoxShadow).map((el) => ({
                element: el,
                boxShadow: el.style.boxShadow,
            }));
            elementsWithBoxShadow.forEach((el) => {
                el.style.boxShadow = "none";
            });

            const cardElements = resultElement.querySelectorAll(".MnvContainer .card,.MnvContainer .card-header");
            const originalBgColors = Array.from(cardElements).map((el) => ({
                element: el,
                backgroundColor: el.style.backgroundColor,
            }));
            cardElements.forEach((el) => {
                el.style.backgroundColor = "#ffffff";
            });

            if (TrendMethod) {
                resultElement.style.transform = "scaleY(0.8)";
                resultElement.style.transformOrigin = "top";
            }

            html2pdf()
                .set({
                    margin: TrendMethod ? 8 : 8,
                    filename: "document.pdf",
                    image: { type: "png", quality: 1 },
                    pagebreak: { mode: ["css"] },
                    html2canvas: {
                        scrollY: 0,
                        scrollX: 0,
                        scale: 1,
                        dpi: 300,
                        letterRendering: true,

                        ignoreElements: (element) =>
                            element.id === "pdf-button-area" ||
                            element.classList.contains("card-header-pills") ||
                            element.classList.contains("selectModelFomula"),
                    },
                    jsPDF: {
                        orientation: "landscape",
                        unit: "mm",
                        format: TrendMethod ? [420, 297] : "a3",
                        compressPDF: true,
                    },
                })
                .from(resultElement)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    if (TrendMethod) {
                        const totalPages = pdf.internal.getNumberOfPages();
                        if (totalPages > 1) {
                            pdf.deletePage(2);
                        }
                    }
                })
                .save()
                .finally(() => {
                    if (TrendMethod) {
                        resultElement.style.transform = "none";
                    }

                    originalStyles.forEach(({ element, boxShadow }) => {
                        element.style.boxShadow = boxShadow;
                    });

                    originalBgColors.forEach(({ element, backgroundColor }) => {
                        element.style.backgroundColor = backgroundColor;
                    });

                    this.isLoadingPdf = false;
                });
        },
        getBaseDayTime(baseDay) {
            if (!this.isEmpty(baseDay) && baseDay.length === 2) {
                this.detailInfo.fromDate = baseDay[0];
                this.detailInfo.toDate = baseDay[1];
            } else if (typeof baseDay === "string") {
                this.detailInfo.fromDate = baseDay;
                this.detailInfo.toDate = baseDay;
            } else return;
        },
        getCompareDayTime(compDay) {
            if (!this.isEmpty(compDay) && compDay.length === 2) {
                this.detailInfo.fromDateComp = compDay[0];
                this.detailInfo.toDateComp = compDay[1];
            } else if (typeof compDay === "string") {
                this.detailInfo.fromDateComp = compDay;
                this.detailInfo.toDateComp = compDay;
            } else return;
        },
        setDayTime(detail) {
            const timeDsvnType = ["DAY", "MONTH", "YEAR", "HOURBYDAY", "DAYBYMONTH", "MONTHBYYEAR"];

            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();

            this.rangeBooleanlocal =
                (timeDsvnType.includes(detail.timeDsvn) && detail.analysisMethod !== "Goal") ||
                detail?.analysisMethod === "MNV";

            if (detail.fromDate && detail.toDate && this.rangeBooleanlocal) {
                if (detail.analysisMethod === "MNV") this.baseDayTime = [detail.fromDate, detail.toDate];
                else {
                    if (detail.timeDsvn === "DAY") {
                        // 해당 월의 첫날 (일은 1)
                        const firstDay = moment(new Date(year, month, 1)).format("YYYY-MM-DD");
                        // 해당 월의 마지막 날: 다음 달의 0일은 현재 월의 마지막 날을 의미함
                        const lastDay = moment(new Date(year, month + 1, 0)).format("YYYY-MM-DD");
                        this.baseDayTime = [firstDay, lastDay];
                        this.detailInfo.fromDate = firstDay;
                        this.detailInfo.toDate = lastDay;
                    } else if (detail.timeDsvn === "MONTH") {
                        const firstMonth = moment(new Date(year, 0)).format("YYYY-MM");
                        const lastMonth = moment(new Date(year, 11)).format("YYYY-MM");
                        this.baseDayTime = [firstMonth, lastMonth];
                        this.detailInfo.fromDate = firstMonth;
                        this.detailInfo.toDate = lastMonth;
                    } else {
                        this.baseDayTime = [String(year - 1), String(year)];
                        this.detailInfo.fromDate = String(year - 1);
                        this.detailInfo.toDate = year;
                    }
                }
            } else if (detail.fromDate && detail.toDate && !this.rangeBooleanlocal) {
                if (detail.timeDsvn === "DAYBYMONTH") {
                    const currentMonth = moment(new Date(year, month)).format("YYYY-MM");
                    this.baseDayTime = currentMonth;
                    this.detailInfo.fromDate = currentMonth;
                    this.detailInfo.toDate = currentMonth;
                } else if (detail.timeDsvn == "MONTHBYYEAR") {
                    this.baseDayTime = String(year);
                    this.detailInfo.fromDate = String(year);
                    this.detailInfo.toDate = String(year);
                } else {
                    this.baseDayTime = moment(new Date()).format("YYYY-MM-DD");
                    this.detailInfo.fromDate = moment(new Date()).format("YYYY-MM-DD");
                    this.detailInfo.toDate = moment(new Date()).format("YYYY-MM-DD");
                }
            } else {
                this.baseDayTime = null;
            }

            if (detail.fromDateComp && detail.toDateComp && this.rangeBooleanlocal) {
                this.compareDayTime = [detail.fromDateComp, detail.toDateComp];
            } else if (detail.fromDateComp && detail.toDateComp && !this.rangeBooleanlocal) {
                this.compareDayTime = detail.fromDateComp;
            } else {
                this.compareDayTime = null;
            }
        },
        // setDayTime(detail) {
        //     this.rangeBooleanlocal =
        //         (["MONTH", "DAY", "YEAR", "HOURBYDAY", "DAYBYMONTH", "MONTHBYYEAR"].includes(detail.timeDsvn) &&
        //             detail.analysisMethod !== "Goal") ||
        //         detail?.analysisMethod === "MNV";

        //     if (detail.fromDate && detail.toDate && this.rangeBooleanlocal) {
        //         this.baseDayTime = [detail.fromDate, detail.toDate];
        //     } else if (detail.fromDate && detail.toDate && !this.rangeBooleanlocal) {
        //         this.baseDayTime = detail.fromDate;
        //     } else {
        //         this.baseDayTime = null;
        //     }
        //     if (detail.fromDateComp && detail.toDateComp && this.rangeBooleanlocal) {
        //         this.compareDayTime = [detail.fromDateComp, detail.toDateComp];
        //     } else if (detail.fromDateComp && detail.toDateComp && !this.rangeBooleanlocal) {
        //         this.compareDayTime = detail.fromDateComp;
        //     } else {
        //         this.compareDayTime = null;
        //     }
        // },
        changeRange(val) {
            this.rangeBooleanlocal = val;
        },
        customCompareType(type) {
            const compareTypeArr = this.$store.state.commonCodes.compareType;
            const filterConditions = {
                YEAR: (value) => value === "User",
                MONTH: (value) => ["LastYear", "Avg3Year", "Avg5Year", "Avg10Year", "User"].includes(value),
                DAY: (value) =>
                    ["LastYear", "LastMonth", "LastWeek", "Avg3Year", "Avg5Year", "Avg10Year", "User"].includes(value),
                HOUR: (value) =>
                    [
                        "LastDay",
                        "LastYear",
                        "LastMonth",
                        "LastWeek",
                        "Avg3Day",
                        "Avg7Day",
                        "Avg30Day",
                        "Avg3Month",
                        "User",
                    ].includes(value),
                QUARTER: (value) =>
                    [
                        "LastDay",
                        "LastYear",
                        "LastMonth",
                        "LastWeek",
                        "Avg3Day",
                        "Avg7Day",
                        "Avg30Day",
                        "Avg3Month",
                        "User",
                    ].includes(value),
            };

            const customCompareTypeArr = compareTypeArr.filter((item) => {
                const condition = filterConditions[type];
                return condition ? condition(item.value) : true;
            });
            this.compareTypeArr = customCompareTypeArr;
        },
        async showStatisticModal(event) {
            // 모달
            await event.stopPropagation();
            this.$refs.statisticModal.show();
        },
        async handleInfoPanel(event) {
            await event.stopPropagation();
            this.hideInfoPanel = !this.hideInfoPanel;
        },
        switchVariables(method) {
            if (method === "Trend") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Influence" || item.roleType === "Main"
                );
            } else if (method === "Diff") {
                this.detailInfo.variables.forEach((item) => {
                    if (item.roleType !== "Influence" && item.roleType !== "Main") {
                        item.roleType = "Compare";
                    }
                });
            } else if (method === "Comp") {
                this.detailInfo.variables.forEach((item) => {
                    if (item.roleType !== "Influence" && item.roleType !== "Main") {
                        item.roleType = "Component";
                    }
                });
            } else if (method === "Reltn") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Influence" || item.roleType === "Main"
                );
            } else if (method === "Goal") {
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Goal" || item.roleType === "Main" || item.roleType === "Predict"
                );
            } else if (method === "MNV") {
                // this.detailInfo.variables = this.detailInfo.variables.filter(
                //     (item) => item.roleType === "Independ" || item.roleType === "Main"
                // );
                this.detailInfo.variables = this.detailInfo.variables.filter(
                    (item) => item.roleType === "Depend" || item.roleType === "Independ"
                );
            }
        },
    },
};
</script>

<style scoped>
.view-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 20px 10px 20px;
}

.model-info {
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 10px !important;
    display: flex;
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.button-shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 0px 8px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.others {
    background-color: #cfd8dc;
    color: #000;
}

.accordion-title {
    background-color: #eeeeee;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: solid #eeeeee 1px;
    border-radius: 6px 6px 0px 0px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.result-itemview {
    /* height: 83%; */
    height: calc(100% - 50px);
    overflow-y: auto;
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #eeeeee 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.list-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.list-notification-text {
    font-size: 15px;
    font-weight: bold;
}
.analysisSpinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
