<template>
    <div class="row m-0">
        <!--  :toggleFields="['enabled']" :transCodes="transCodes" -->
        <!--  @row-click="handleItemClick" @toggle-changed="handleItemToggle" -->
        <TableList
            ref="TableList"
            :title="'분석 기록 목록'"
            :keyField="'modelIdx'"
            :columns="columns"
            :rows="commentList"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="true"
            :useFilter="false"
            :buttonField="'snapshot'"
            :buttonText="'SnapShot'"
            @buttonClick="showSnapShotModal"
            @button-click="handleListButtonClick"
        />
        <b-modal ref="addAnalCommentModal" centered size="md" hide-footer hide-header @hidden="hideAddCommentModal">
            <!-- <template #modal-header>
                <h5 class="mb-0">{{ "분석 기록 추가" }}</h5>
            </template> -->
            <!-- <div class="row m-0">
                <label for="comment">{{ "분석 기록 입력" }}</label>
                <input v-model="comment.comment" type="text" class="form-control" />
            </div> -->
            <div class="row m-0">
                <div class="col-12 p-0 mb-2">
                    <span>{{
                        "분석에 기록을 남기면 북석 기록과 함께 현재 분석 결과가 저장되어 언제라도 간편하게 분석 이력을 확인할 수 있습니다."
                    }}</span>
                </div>
                <div class="col-9 p-0">
                    <textarea
                        v-model="comment.comment"
                        class="form-control"
                        rows="5"
                        placeholder="분석 기록을 입력하세요."
                        style="resize: none; overflow-y: auto;"
                    ></textarea>
                </div>
                <div class="col-3 pr-0">
                    <div class="col-12 p-0" style="height: 48%; margin-bottom: 4%;">
                        <button
                            class="btn btn-primary"
                            type="button"
                            style="width: 100%; height: 100%;"
                            @click="saveAnalysisComment"
                        >
                            {{ "저장" }}
                        </button>
                    </div>
                    <div class="col-12 p-0" style="height: 48%;">
                        <button
                            class="btn btn-secondary"
                            type="button"
                            style="width: 100%; height: 100%;"
                            @click="hideAddCommentModal"
                        >
                            {{ "취소" }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="flex: 1; margin-right: 0.5rem;"
                        @click="saveAnalysisComment"
                    >
                        {{ "추가" }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="flex: 1; margin-left: 0.5rem;"
                        @click="() => $refs.addAnalCommentModal.hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template> -->
        </b-modal>
        <b-modal
            id="snapshot-modal"
            body-class="snapshot-modal-body"
            ref="analHistorySnapShotModal"
            centered
            @hidden="hideSnapShotModal"
        >
            <template #modal-header>
                <h5 class="m-0">{{ "분석 스냅샷" }}</h5>
            </template>
            <div v-if="!isEmpty(commentDetail)" class="row m-0" style="padding: 1rem !important;">
                <div v-if="!isEmpty(commentDetail.snapshot)" class="col-12 p-0" style="display: flex;">
                    <ResultChartPanel
                        :class="!showReltnGoal ? 'col-9 pl-0' : 'col-12'"
                        :detailInfo="detailInfo"
                        :resultData="commentDetail.snapshot"
                        :calendarList="calendarList"
                        :analysisMethod="detailInfo.analysisMethod"
                    />
                    <div v-if="!showReltnGoal" class="col-3 pr-0">
                        <ResultInfoPanel
                            :detailInfo="detailInfo"
                            :calendarList="calendarList"
                            @detail="showAnalysisHistory"
                            @getEnergyCalendarList="getEnergyCalendarList"
                        />
                    </div>
                </div>
                <div v-else style="width: 100%;">
                    <span class="m-0">{{ "분석 스냅샷이 없습니다." }}</span>
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button class="btn btn-secondary" style="flex: 1;" @click="hideSnapShotModal">
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import ResultChartPanel from "./ResultChartPanel.vue";
import ResultInfoPanel from "./ResultInfoPanel.vue";
import moment from "moment";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: ["detailInfo", "resultData"],
    components: {
        TableList,
        ResultChartPanel,
        ResultInfoPanel,
    },
    data() {
        return {
            commentList: [],
            comment: {
                comment: "",
                snapshot: null,
            },
            commentDetail: null,
            controls: [
                { event: "regist", label: "+", class: "btn-primary" },
                { event: "delete", label: "-", class: "btn-danger" },
            ],
            columns: [
                {
                    label: this.$t("기록 일시"),
                    field: "regDate",
                    type: "text",
                    width: "130px",
                },
                {
                    label: this.$t("기록 내용"),
                    field: "comment",
                    type: "text",
                },
                // {
                //     label: this.$t("변경 일시"),
                //     field: "modDate",
                //     type: "text",
                //     width: "130px",
                // },
                {
                    label: this.$t("작성자"),
                    field: "regWorker",
                    type: "text",
                    width: "80px",
                },
                {
                    label: this.$t("스냅샷"),
                    field: "snapshot",
                    type: "text",
                    width: "100px",
                },
            ],
            calendarList: [],
            showReltnGoal: false,
            popupMessages,
        };
    },
    computed: {},
    watch: {},
    async created() {
        await this.getAnalysisCommentList();
        await this.getEnergyCalendarList(this.detailInfo.fromDate, this.detailInfo.toDate);
    },
    mounted() {},
    methods: {
        async getAnalysisCommentList() {
            if (this.detailInfo.modelIdx) {
                try {
                    let result = await backEndApi.analysisModel.getAnalysisCommentList(this.detailInfo.modelIdx);
                    if (result.status == 200) {
                        this.commentList = result.data;
                        this.commentList.forEach((comment) => {
                            if (!this.isEmpty(comment.regDate))
                                comment.regDate = moment(comment.regDate).format("YYYY-MM-DD HH:mm:ss");
                            if (!this.isEmpty(comment.modDate))
                                comment.modDate = moment(comment.modDate).format("YYYY-MM-DD HH:mm:ss");
                        });
                    } else {
                        if (result.data.errcode !== "NoDataFound") {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                            return;
                        }
                        // this.alertWarning(`${result.data.message}`);
                    }
                } catch (e) {
                    console.error(e);
                }
            } else return;
        },
        async getAnalysisComment(modelIdx, regDate) {
            let complete;
            try {
                let result = await backEndApi.analysisModel.getAnalysisComment(modelIdx, regDate);
                if (result.status == 200) {
                    complete = true;
                    this.commentDetail = result.data;
                } else {
                    complete = false;
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
            return complete;
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        registNew() {
            this.$refs.addAnalCommentModal.show();
        },
        hideAddCommentModal() {
            this.$refs.addAnalCommentModal.hide();
        },
        async deleteChecked() {
            try {
                let confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;

                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        let result = await backEndApi.analysisModel.deleteAnalysisComment(
                            this.detailInfo.modelIdx,
                            item.regDate
                        );
                        if (result.status !== 200) {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                            return;
                        }
                    });
                    await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                    await this.getAnalysisCommentList();
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async saveAnalysisComment() {
            let comment = {
                modelIdx: this.detailInfo.modelIdx,
                comment: this.comment.comment,
                snapshot: this.resultData,
            };
            try {
                let result = await backEndApi.analysisModel.saveAnalysisComment(comment);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    await this.getAnalysisCommentList();
                    await this.$refs.addAnalCommentModal.hide();
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async showSnapShotModal(data) {
            this.$refs.analHistorySnapShotModal.show();
            await this.setReltnGoal();
            await this.getAnalysisComment(this.detailInfo.modelIdx, data.regDate);
        },
        hideSnapShotModal() {
            this.commentDetail = null;
            this.$refs.analHistorySnapShotModal.hide();
        },
        async getEnergyCalendarList(fromDate, toDate) {
            try {
                let result = await backEndApi.analysisModel.getEnergyCalendarList(fromDate, toDate);
                if (result.status == 200) {
                    this.calendarList = result.data;
                    if (!this.isEmpty(this.calendarList)) {
                        this.calendarList.forEach((calendar) => {
                            calendar.regDate = moment(calendar.regDate).format("YYYY-MM-DD HH:mm:ss");
                            calendar.modDate = moment(calendar.modDate).format("YYYY-MM-DD HH:mm:ss");
                        });
                    }
                } else this.calendarList = [];
            } catch (e) {
                console.error(e);
            }
        },
        setReltnGoal() {
            if (this.detailInfo.analysisMethod == "Trend") this.showReltnGoal = false;
            else if (this.detailInfo.analysisMethod == "Diff") this.showReltnGoal = false;
            else if (this.detailInfo.analysisMethod == "Comp") this.showReltnGoal = false;
            else if (this.detailInfo.analysisMethod == "Reltn") this.showReltnGoal = true;
            else if (this.detailInfo.analysisMethod == "Goal") this.showReltnGoal = true;
            else this.showReltnGoal = true;
        },
        showAnalysisHistory() {
            this.$emit("show-history");
        },
    },
};
</script>

<style scoped></style>
