<template>
    <div class="row m-0">
        <TableList
            ref="TableList"
            :title="'주요 이벤트 목록'"
            :keyField="'id'"
            :columns="columns"
            :rows="calendarList"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="true"
            :useFilter="false"
            @button-click="handleListButtonClick"
        />
        <b-modal ref="addAnalEventModal" centered size="md">
            <template #modal-header>
                <h5 class="mb-0">{{ "이벤트 추가" }}</h5>
            </template>
            <div class="row m-0">
                <div class="col-4">
                    <label for="title">{{ "제목" }}</label>
                    <input v-model="calendar.title" type="text" class="form-control" id="title" />
                </div>
                <!-- @input="updateValue" -->
                <div class="col-4">
                    <label for="date">{{ "일자" }}</label>
                    <date-picker
                        ref="datePicker"
                        v-model="calendar.date"
                        :type="'date'"
                        :range="false"
                        :valueType="'YYYY-MM-DD'"
                        lang="ko"
                        :placeholder="'이벤트 일자'"
                        :format="'YYYY-MM-DD'"
                        :popup-style="{ zIndex: 1050 }"
                        :append-to-body="true"
                    ></date-picker>
                </div>
                <div class="col-4">
                    <label for="category">{{ "카테고리" }}</label>
                    <input v-model="calendar.category" type="text" class="form-control" id="category" />
                </div>
                <div class="col-12 mt-2">
                    <label for="description">{{ "내용" }}</label>
                    <textarea
                        v-model="calendar.description"
                        type="text"
                        class="form-control"
                        id="description"
                        style="resize: none; overflow-y: auto;"
                        rows="3"
                    ></textarea>
                </div>
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="flex: 1; margin-right: 0.5rem;"
                        @click="saveAnalysisComment"
                    >
                        {{ "추가" }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="flex: 1; margin-left: 0.5rem;"
                        @click="() => $refs.addAnalEventModal.hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import DatePicker from "vue2-datepicker";
// import moment from "moment";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: ["detailInfo", "calendarList"],
    components: {
        TableList,
        DatePicker,
    },
    data() {
        return {
            // commentList: [],
            // comment: null,
            controls: [
                { event: "regist", label: "+", class: "btn-primary" },
                { event: "delete", label: "-", class: "btn-danger" },
            ],
            columns: [
                // {
                //     label: this.$t("이벤트 일자"),
                //     field: "eventInfo",
                //     type: "text",
                //     compositionFields: ["date", "id"],
                //     width: "100px",
                // },
                {
                    label: this.$t("이벤트 일자"),
                    field: "date",
                    type: "text",
                    width: "100px",
                },
                {
                    label: this.$t("내용"),
                    field: "description",
                    type: "text",
                },
                {
                    label: this.$t("카테고리"),
                    field: "category",
                    type: "text",
                    width: "80px",
                },
                {
                    label: this.$t("등록 일자"),
                    field: "regDate",
                    type: "text",
                    width: "130px",
                },
                // {
                //     label: this.$t("변경 일자"),
                //     field: "modDate",
                //     type: "text",
                //     width: "130px",
                // },
                {
                    label: this.$t("작성자"),
                    field: "regWorker",
                    type: "text",
                    width: "80px",
                },
            ],
            calendar: {
                title: "",
                date: "",
                category: "",
                description: "",
            },
            popupMessages,
        };
    },
    computed: {},
    watch: {},
    created() {
        // this.getAnalysisCommentList();
    },
    mounted() {},
    methods: {
        // async getAnalysisCommentList() {
        //     if (this.detailInfo.modelIdx) {
        //         try {
        //             let result = await backEndApi.analysisModel.getAnalysisCommentList(this.detailInfo.modelIdx);
        //             if (result.status == 200) {
        //                 this.commentList = result.data;
        //                 this.commentList.forEach((comment) => {
        //                     if (!this.isEmpty(comment.regDate))
        //                         comment.regDate = moment(comment.regDate).format("YYYY-MM-DD HH:mm:ss");
        //                     if (!this.isEmpty(comment.modDate))
        //                         comment.modDate = moment(comment.modDate).format("YYYY-MM-DD HH:mm:ss");
        //                 });
        //             } else {
        //                 return;
        //                 // this.alertWarning(`${result.data.message}`);
        //             }
        //         } catch (e) {
        //             console.error(e);
        //         }
        //     } else return;
        // },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;
                case "delete":
                    await this.deleteChecked();
                    break;
                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        registNew() {
            this.$refs.addAnalEventModal.show();
        },
        async deleteChecked() {
            try {
                let confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;
                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        let result = await backEndApi.analysisModel.deleteEnergyCalendar(item.id);
                        if (result.status !== 200) {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                            return;
                        }
                    });
                    await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                    await this.$emit("refresh", this.detailInfo.fromDate, this.detailInfo.toDate);
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async saveAnalysisComment() {
            try {
                let result = await backEndApi.analysisModel.saveEnergyCalendar(this.calendar);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    await this.$emit("refresh", this.detailInfo.fromDate, this.detailInfo.toDate);
                    await this.$refs.addAnalEventModal.hide();
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped></style>
