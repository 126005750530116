import { render, staticRenderFns } from "./MnvBasePeriodTable.vue?vue&type=template&id=143ea4ef&scoped=true&"
import script from "./MnvBasePeriodTable.vue?vue&type=script&lang=js&"
export * from "./MnvBasePeriodTable.vue?vue&type=script&lang=js&"
import style0 from "./MnvBasePeriodTable.vue?vue&type=style&index=0&id=143ea4ef&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143ea4ef",
  null
  
)

export default component.exports