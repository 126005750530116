<template>
    <div style="width: 100%; height: 100%;">
        <div ref="lineChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import { mapState } from "vuex";

const baseDataColor = "#FF5722";
// 요청으로 인해 비교차트 또한 주색상을 그레이스케일로 조정하였습니다. author 선구
//  const compDataColor = "#A5D6A7";
const compDataColor = "#E0E0E0";
const secondaryColor = "#E0E0E0";

export default {
    props: [
        "detailInfo",
        "chartResize",
        "resultData",
        "narrow",
        "calendarList",
        "switchValues",
        "showToolbox",
        "showLineAndTick",
        "invisibleLabel",
        "topGrid",
        "hideInfoPanel",
    ],
    components: {},
    data() {
        return {
            chart: null,
            // chartData: [],
            baseDataName: "",
            compDataName: "",
            reltnDataName: "",
            baseDataset: [],
            compDataset: [],
            reltnDataset: [],
            offSetDataset: [],
            xAxisLabel: [],
            unitMap: {},

            filterReltnHeader: null,
            ptMapsFactor: null,
            localCompareTypeVal: null,

            summedData: null,
        };
    },
    computed: {
        chartType() {
            return !this.isEmpty(this.detailInfo.dispType) && this.detailInfo.dispType !== "Area"
                ? this.detailInfo.dispType.toLowerCase()
                : "line";
        },
        ...mapState({
            commonCodes: (state) => state.commonCodes,
        }),
        localCompareType() {
            return this.detailInfo.compareType
                ? this.commonCodes.compareType.find((item) => item.value == this.detailInfo.compareType).text
                : "-";
        },
        targetInfo() {
            return this.detailInfo.variables.find((item) => item.roleType === "Main") ?? null;
        },
    },
    watch: {
        resultData() {
            this.prepareChartData();
        },
        chartResize() {
            if (this.chart) {
                this.chart.resize(); // chartResize 변경 시 차트를 리사이즈
            }
        },
        hideInfoPanel() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        switchValues: {
            handler() {
                if (this.chart) {
                    this.prepareChartData();
                }
            },
            deep: true,
        },
    },
    created() {},
    mounted() {
        this.prepareChartData();
        window.addEventListener("resize", () => {
            this.chart.resize();
        });
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
        window.removeEventListener("resize", () => {
            this.chart.resize();
        });
    },
    methods: {
        timeConvert(reg) {
            const formatMap = {
                HOUR: "HH:mm",
                DAY: "YYYY-MM-DD",
                MONTH: "YYYY-MM-DD",
                YEAR: "YYYY-MM",
            };

            const format = formatMap[this.detailInfo.timeDsvn] || "YYYY-MM-DD HH:mm:ss";

            return moment(new Date(reg)).format(format);
        },
        async prepareChartData() {
            this.xAxisLabel = [];
            this.reltnDataset = [];

            if (!this.isEmpty(this.resultData) && !this.isEmpty(this.resultData.headers)) {
                this.resultData.values.regDt.forEach((reg) => {
                    this.xAxisLabel.push(this.timeConvert(reg));
                });

                const findTargetHeader = this.resultData.headers.find(
                    (header) => header.ptIdx == this.targetInfo.ptIdx
                );

                // 주 데이터(분석 데이터) 셋
                if (!this.isEmpty(findTargetHeader)) {
                    this.baseDataset = this.resultData.values[findTargetHeader.key];
                    this.baseDataName = findTargetHeader.ptName;
                    const baseUnit = this.$store.state.units.find(
                        (unit) => unit.value == findTargetHeader.unitSmallCode
                    );
                    this.unitMap[this.baseDataName] = findTargetHeader.dispUnit
                        ? findTargetHeader.dispUnit
                        : baseUnit.text;
                    // 비교 데이터 셋
                    this.compDataset = this.resultData.comparison[findTargetHeader.key]
                        ? this.resultData.comparison[findTargetHeader.key].value
                        : [];

                    this.offSetDataset = this.resultData.comparison[findTargetHeader.key]
                        ? this.resultData.comparison[findTargetHeader.key].offset
                        : [];
                }

                // 영향인자 데이터 셋
                this.ptMapsFactor = this.detailInfo.variables.filter((varItem) => varItem.roleType == "Influence");
                if (!this.isEmpty(this.ptMapsFactor)) {
                    this.filterReltnHeader = this.resultData.headers.filter((header) =>
                        this.ptMapsFactor.some((factor) => header.ptIdx == factor.ptIdx)
                    );
                    if (!this.isEmpty(this.filterReltnHeader)) {
                        if (this.filterReltnHeader.length > 1) {
                            this.filterReltnHeader.forEach((reltn) => {
                                this.reltnDataset.push(this.resultData.values[reltn.key]);
                                const reltnUnit = this.$store.state.units.find(
                                    (unit) => unit.value == reltn.unitSmallCode
                                );
                                this.unitMap[reltn.ptName] = reltn.dispUnit ? reltn.dispUnit : reltnUnit.text;
                            });
                        } else {
                            this.reltnDataset.push(this.resultData.values[this.filterReltnHeader[0].key]);
                            const reltnUnit = this.$store.state.units.find(
                                (unit) => unit.value == this.filterReltnHeader[0].unitSmallCode
                            );
                            this.unitMap[this.filterReltnHeader[0].ptName] = reltnUnit.text ?? "kWh";
                        }
                    }
                }
            }
            await this.renderChart();
        },

        async renderChart() {
            this.chart = echarts.init(this.$refs.lineChart);
            // const xAxisLabel = this.xAxisLabel;
            const unitMap = this.unitMap;
            const dispUnit = this.detailInfo.dispUnit;
            // const baseData = this.baseDataset;
            let markLineData = [];
            let outlierData = [];
            let eventData = [];
            if (this.switchValues?.outlier && this.resultData.statsDesc?.outliers?.length) {
                markLineData = this.resultData.statsDesc.outliers.map((outlier) => {
                    const xValue = outlier?.regDt ?? 0;
                    const yValue = outlier?.ptVal ?? 0;

                    return [
                        {
                            name: "start",
                            coord: [xValue, 0],
                            label: { show: false },
                            lineStyle: { type: "dashed", color: "grey" },
                            symbol: "none",
                        },
                        {
                            name: "end",
                            coord: [xValue, yValue],
                            symbol: "none",
                        },
                    ];
                });
            } else {
                markLineData = []; // 데이터가 없으면 빈 배열 유지
            }

            if (this.switchValues?.event && Array.isArray(this.calendarList) && this.calendarList.length > 0) {
                this.calendarList.forEach((calendar) => {
                    const findIndex = this.xAxisLabel.findIndex((item) => item === calendar.date);
                    if (findIndex === -1 || !calendar?.date) return;

                    const val = this.baseDataset?.[findIndex] ?? 0;

                    markLineData.push([
                        {
                            name: "start",
                            coord: [calendar.date ?? 0, 0],
                            label: { show: false },
                            lineStyle: { type: "dashed", color: "grey" },
                            symbol: "none",
                        },
                        {
                            name: "end",
                            coord: [calendar.date ?? 0, val],
                            symbol: "none",
                        },
                    ]);
                });
            } else {
                markLineData = [];
            }

            if (this.switchValues?.mean && this.resultData.statsDesc) {
                markLineData.push(
                    {
                        name: "Mean",
                        yAxis: this.resultData.statsDesc.mean ?? 0,
                        lineStyle: { type: "dashed", color: "grey" },
                        label: { formatter: "평균" },
                    },
                    {
                        name: "Max",
                        yAxis: this.resultData.statsDesc.max ?? 0,
                        lineStyle: { type: "dashed", color: "grey" },
                        label: { formatter: "최대값" },
                    },
                    {
                        name: "Min",
                        yAxis: this.resultData.statsDesc.min ?? 0,
                        lineStyle: { type: "dashed", color: "grey" },
                        label: { formatter: "최소값" },
                    }
                );
            }
            if (this.switchValues?.quartile && this.resultData.statsDesc?.quartile) {
                markLineData.push(
                    {
                        name: "Q1",
                        yAxis: this.resultData.statsDesc.quartile.Q1 ?? 0,
                        lineStyle: { type: "dashed", color: "grey" },
                        label: { formatter: "Q1" },
                    },
                    {
                        name: "Q2",
                        yAxis: this.resultData.statsDesc.quartile.Q2 ?? 0,
                        lineStyle: { type: "dashed", color: "grey" },
                        label: { formatter: "Q2" },
                    },
                    {
                        name: "Q3",
                        yAxis: this.resultData.statsDesc.quartile.Q3 ?? 0,
                        lineStyle: { type: "dashed", color: "grey" },
                        label: { formatter: "Q3" },
                    }
                );
            }

            // 이상치 데이터 처리
            if (this.switchValues?.outlier) {
                outlierData = (this.resultData.statsDesc.outliers || []).map((outlier) => ({
                    name: "이상치",
                    coord: [this.timeConvert(outlier.regDt), outlier.ptVal],
                    value: outlier.ptVal,
                    itemStyle: { color: "#FF0000" },
                }));
            }
            // 이벤트 데이터 처리
            if (this.switchValues?.event && !this.isEmpty(this.calendarList)) {
                this.calendarList.forEach((calendar) => {
                    let val = 0;
                    const findIndex = this.xAxisLabel.findIndex((item) => item === calendar.date);
                    val = this.baseDataset[findIndex];
                    eventData.push({
                        coord: [calendar.date, val],
                        itemStyle: { color: "#2979FF" },
                        name: calendar.title,
                        value: val,
                        id: calendar.id,
                        type: "calendar",
                    });
                });
            }

            let series = [
                {
                    name: "증감량",
                    type: "bar",
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    data: this.offSetDataset,
                    itemStyle: {
                        color: (params) => (params.value >= 0 ? "#EF9A9A" : "#90CAF9"),
                    },
                },
                {
                    name: `${this.baseDataName}`,
                    type: this.chartType,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 0.4,
                    symbolSize: 3,
                    data: this.baseDataset,
                    lineStyle: { color: baseDataColor, width: 2 },
                    itemStyle: { color: baseDataColor },
                    markPoint: {
                        data: [
                            ...(this.switchValues?.outlier ? outlierData : []), // 이상치 데이터 반영
                            ...(this.switchValues?.event ? eventData : []), // 이벤트 데이터 반영
                        ],
                        symbol: "pin",
                        symbolSize: 45,
                        symbolOffset: [0, -10],
                        label: {
                            formatter: (param) => {
                                if (param.name === "이상치") {
                                    return `{boldStyle|이상}`;
                                } else if (param.name) {
                                    return `{boldStyle|${param.name}}`;
                                }
                                return "";
                            },
                            rich: {
                                boldStyle: {
                                    fontWeight: 700,
                                    color: "#E0E0E0",
                                    fontSize: 12,
                                },
                            },
                        },
                    },
                    markLine: {
                        data: markLineData.length > 0 ? markLineData : [],
                        symbol: "none",
                    },
                },
                {
                    name: `${this.localCompareType}`,
                    type: this.chartType,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 0.4,
                    symbolSize: 3,
                    zlevel: 0,
                    data: this.compDataset,
                    z: 1,
                    lineStyle: {
                        color: compDataColor,
                        width: 2,
                    },
                    itemStyle: {
                        color: compDataColor,
                    },
                },
            ];

            if (this.ptMapsFactor.length > 0) {
                this.reltnDataset.forEach((data, index) => {
                    let item = {
                        name: this.filterReltnHeader[index].ptName,
                        type: this.chartType,
                        smooth: 0.4,
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        data: data,
                        lineStyle: {
                            color: secondaryColor,
                            width: 2,
                        },
                        itemStyle: {
                            color: secondaryColor,
                        },
                    };
                    if (this.detailInfo.dispType == "Area") {
                        item.areaStyle = {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: secondaryColor + "00" },
                                { offset: 1, color: secondaryColor },
                            ]),
                        };
                    }
                    series.push(item);
                });
            }

            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                    formatter: function(params) {
                        // 값 축약 함수
                        function formatValue(value) {
                            const absValue = Math.abs(value); // 절대값 처리
                            let formattedValue;
                            if (absValue >= 1e9) {
                                formattedValue = Number.isInteger(absValue / 1e9)
                                    ? absValue / 1e9 + "G"
                                    : (absValue / 1e9).toFixed(1) + "G";
                            } else if (absValue >= 1e6) {
                                formattedValue = Number.isInteger(absValue / 1e6)
                                    ? absValue / 1e6 + "M"
                                    : (absValue / 1e6).toFixed(1) + "M";
                            } else if (absValue >= 1e3) {
                                formattedValue = Number.isInteger(absValue / 1e3)
                                    ? absValue / 1e3 + "K"
                                    : (absValue / 1e3).toFixed(1) + "K";
                            } else {
                                formattedValue = Number.isInteger(absValue) ? absValue.toString() : absValue.toFixed(1);
                            }

                            return value < 0 ? `-${formattedValue}` : formattedValue; // 원래 부호 추가
                        }

                        let result = `<div style="font-size: 12px; width: 230px;">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((param) => {
                            result += `<div style="display: flex; justify-content: space-between;">
                                                <div>
                                                    ${param.marker}
                                                    <span>
                                                        ${param.seriesName}
                                                        (${
                                                            unitMap[param.seriesName]
                                                                ? unitMap[param.seriesName]
                                                                : dispUnit
                                                        })
                                                    </span>
                                                </div>
                                                <span style="font-weight: bold;">
                                                    ${formatValue(param.data)}
                                                </span>
                                        </div>`;
                        });

                        result += `</div></div>`;
                        return result;
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                // props로 narrow를 받아서 크기를 동적으로 조정합니다. 상관분석에서 차트여러개를 넣으려니 잘 안들어가서 수정합니다. author. 선구
                grid: [
                    {
                        left: this.narrow ? `${this.narrow}%` : "7%",
                        right: this.narrow ? `${this.narrow}%` : "7%",
                        top: this.topGrid ? `${Number(this.topGrid)}%` : "8%",
                        height: "5%",
                    },
                    {
                        left: this.narrow ? `${this.narrow}%` : "7%",
                        right: this.narrow ? `${this.narrow}%` : "7%",
                        top: this.topGrid ? `${Number(this.topGrid) + 7}%` : "20%",
                        bottom: "1%",
                        height: "60%",
                    },
                    {
                        left: this.narrow ? `${this.narrow}%` : "7%",
                        right: this.narrow ? `${this.narrow}%` : "7%",
                        top: this.topGrid ? "75%" : "87%",
                        // bottom: "1%",
                        height: this.topGrid ? "18%" : "12%",
                    },
                ],
                xAxis: [
                    {
                        gridIndex: 0,
                        type: "category",
                        data: this.xAxisLabel,
                        boundaryGap: this.chartType === "bar",
                        axisLabel: { show: false },
                        axisLine: {
                            show: !this.showLineAndTick, // 동적으로 x축 라인을 보여줄지 결정
                        },
                        axisTick: {
                            show: !this.showLineAndTick, // 동적으로 x축 라인의 tick을 보여줄지 결정정
                        },
                    },
                    {
                        gridIndex: 1,
                        type: "category",
                        boundaryGap: this.chartType === "bar",
                        // boundaryGap: true,
                        axisLine: {
                            show: !this.showLineAndTick,
                        },
                        data: this.xAxisLabel,
                        axisPointer: {
                            label: {
                                show: false, // 이 xAxis에서 hover 시 레이블 숨기기
                            },
                        },
                        axisTick: {
                            show: !this.showLineAndTick,
                        },
                    },
                    {
                        show: false,
                        gridIndex: 2,
                        type: "category",
                        boundaryGap: this.chartType === "bar",
                        // boundaryGap: true,
                        axisLine: {
                            show: false, // X축 선 제거
                        },
                        data: this.xAxisLabel,
                        position: "top",
                        axisLabel: {
                            align: "left",
                        },
                    },
                ],
                yAxis: [
                    { type: "value", gridIndex: 0, axisLabel: { show: false }, splitLine: { show: false } },
                    {
                        name: this.invisibleLabel ? "" : `${this.baseDataName} (${this.unitMap[this.baseDataName]})`,
                        nameLocation: "end", // "start", "middle", "end" 중 선택
                        nameTextStyle: {
                            // fontSize: 14, // 텍스트 크기
                            // color: "#333", // 텍스트 색상
                            padding: [0, 0, 0, 150], // 여백 설정
                        },
                        gridIndex: 1,
                        type: "value",
                        min: 0,
                        axisLabel: {
                            align: "right",
                            padding: [0, 5, 0, 0],
                            formatter: function(value) {
                                if (value >= 1e9) {
                                    return Number.isInteger(value / 1e9)
                                        ? value / 1e9 + "G"
                                        : (value / 1e9).toFixed(1) + "G";
                                } else if (value >= 1e6) {
                                    return Number.isInteger(value / 1e6)
                                        ? value / 1e6 + "M"
                                        : (value / 1e6).toFixed(1) + "M";
                                } else if (value >= 1e3) {
                                    return Number.isInteger(value / 1e3)
                                        ? value / 1e3 + "K"
                                        : (value / 1e3).toFixed(1) + "K";
                                } else {
                                    return Number.isInteger(value) ? value.toString() : value.toFixed(1);
                                }
                            },
                        },
                    },
                    {
                        // name: `영향인자`,
                        gridIndex: 2,
                        type: "value",
                        min: 0,
                        inverse: true,
                        axisLabel: {
                            align: "right",
                            padding: [0, 10, 0, 0],
                        },
                        splitNumber: 3,
                    },
                ],
                series: series,
            };

            if (this.showToolbox) {
                option.toolbox = {
                    feature: {
                        dataZoom: {
                            yAxisIndex: false,
                            brushStyle: {
                                borderColor: "#90CAF9",
                                borderWidth: 1,
                                shadowColor: "rgba(0, 0, 0, 0.25)",
                                shadowBlur: 10,
                            },
                        },
                        brush: {
                            type: ["lineX", "clear"], // 선택할 수 있는 브러시 타입
                        },
                    },
                };
                option.dataZoom = [
                    {
                        type: "inside",
                        xAxisIndex: [0, 1, 2],
                        filterMode: "filter",
                    },
                ];
                option.brush = {
                    toolbox: ["lineX", "clear"], // 브러시 도구: 사각형, 폴리곤, 유지, 지우기
                    xAxisIndex: "all", // x축 선택 적용
                    yAxisIndex: "all", // y축 선택 적용
                    brushLink: "all", // 모든 축을 연결하여 동시 선택
                    outOfBrush: {
                        colorAlpha: 0.1, // 선택되지 않은 영역의 투명도
                    },
                    brushStyle: {
                        borderType: "dashed",
                        borderColor: "#90CAF9",
                        borderWidth: 1,
                        shadowColor: "rgba(0, 0, 0, 0.25)",
                        shadowBlur: 10,
                    },
                };
            }

            await this.chart.setOption(option);

            if (this.detailInfo.dispType == "Area") this.setAreaStyle();

            this.chart.on("click", async (params) => {
                if (params.componentType === "markPoint" && params.data.type === "calendar") {
                    await this.$emit("get-evt-detail", params.data.id);
                    await this.$emit("show-evt-detail");
                }
            });

            // 차트에서 특정영역 선택시 발생하는 이벤트
            this.chart.on("brushEnd", (params) => {
                this.$emit("brush-end", params);
            });

            this.chart.on("brushselected", (params) => {
                if (params.batch[0]?.areas.length == 0) this.$emit("brush-clear");
            });

            this.$nextTick(() => {
                if (this.chart) {
                    this.chart.resize();
                }
            });
        },
        setAreaStyle() {
            this.chart.setOption({
                series: [
                    {
                        areaStyle: {},
                    },
                    {
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: baseDataColor },
                                { offset: 1, color: baseDataColor + "00" },
                            ]),
                        },
                    },
                    // 요청으로 인해 비교차트 또한 색상을 그레이스케일로 조정하였습니다.
                    {
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(200, 200, 200, 0.5)" },
                                { offset: 1, color: "rgba(200, 200, 200, 0.1)" },
                            ]),
                        },
                    },
                ],
            });
        },
    },
};
</script>

<style scoped></style>
