<template>
    <div v-if="!isEmpty(resultData)" class="MnvContainer">
        <b-card no-body>
            <transition-group name="fade-slide" mode="out-in" tag="div">
                <!-- <b-tabs pills card v-model="activeTab"> -->
                <!-- <b-tab title="분석 데이터"> -->
                <!-- <transition name="fade-slide" mode="out-in"> -->
                <div v-if="mnvTab === 0" key="analysis-data">
                    <div class="info borderStyle">
                        {{
                            "CxEMS의 M&V 분석 기능은 IPMVP (International Performance Measurement and Verification Protocol) 기준에 따라 제공됩니다."
                        }}
                    </div>
                    <!-- color: #66bb6a; -->
                    <div class="analysisTarget borderStyle">
                        <div class="smallTitle"><i class="fa fas fa-check checkIcon" />{{ "분석 대상" }}</div>
                        <div>
                            <mnv-analysis-target-table
                                :resultData="resultData"
                                @updateFactor="onUpdateFactor"
                            ></mnv-analysis-target-table>
                        </div>
                    </div>
                    <div class="analysisPeriod borderStyle">
                        <div class="smallTitle">
                            <i class="fa fas fa-check checkIcon" />
                            {{ "분석 기간" }}
                        </div>
                        <div>
                            <mnv-analysis-period-table
                                :resultData="resultData"
                                :detailInfo="detailInfo"
                                @updatePeriodName="onUpdatePeriodName"
                            ></mnv-analysis-period-table>
                        </div>
                    </div>
                    <div class="baselinePeriodData borderStyle">
                        <div
                            class="smallTitle"
                            style="display: flex; align-items: center; justify-content: space-between;"
                        >
                            <div>
                                <i class="fa fas fa-check checkIcon" />
                                <span>{{ "베이스라인 기간 데이터 " }}</span>
                                <span>
                                    {{
                                        `(${resultData.data.baseline.regDt[0] ?? "-"} ~ 
                                ${resultData.data.baseline.regDt[resultData.data.baseline.regDt.length - 1] ?? "-"})`
                                    }}
                                </span>
                            </div>
                            <div v-if="mode == 'setting'">
                                <button type="button" class="btn btn-sm btn-primary" @click="showPropMgmtModal">
                                    {{ "변수 관리" }}
                                </button>
                            </div>
                            <span class="badge others errorBadge" v-if="baselineErrorVal"
                                >오류 {{ `${baselineErrorVal}` }}건</span
                            >
                        </div>
                        <mnv-base-period-table
                            :resultData="resultData"
                            :detailInfo="detailInfo"
                            :periodName="periodName[0]"
                        ></mnv-base-period-table>
                    </div>
                    <div class="reportPeriodData borderStyle">
                        <div
                            class="smallTitle"
                            style="display: flex; align-items: center; justify-content: space-between;"
                        >
                            <div>
                                <i class="fa fas fa-check checkIcon" />
                                <span>{{ "보고기간 데이터 " }}</span>
                                <span>
                                    {{
                                        `(${resultData.data.report[0].regDt[0] ?? "-"} ~ 
                            ${resultData.data.report[resultData.data.report.length - 1].regDt[
                                resultData.data.report[resultData.data.report.length - 1].regDt.length - 1
                            ] ?? "-"})`
                                    }}
                                </span>
                            </div>
                            <div v-if="mode == 'setting'">
                                <button type="button" class="btn btn-sm btn-primary" @click="showPropMgmtModal">
                                    {{ "변수 관리" }}
                                </button>
                            </div>
                            <span class="badge others errorBadge" v-if="reportErrorVal"
                                >오류 {{ `${reportErrorVal}` || 0 }}건</span
                            >
                        </div>
                        <mnv-report-period-table
                            :resultData="resultData"
                            :detailInfo="detailInfo"
                            :periodName="periodName"
                        ></mnv-report-period-table>
                    </div>
                    <div class="dataValidation borderStyle">
                        <div
                            class="smallTitle"
                            style="display: flex; align-items: center; justify-content: space-between;"
                        >
                            <div>
                                <i class="fa fas fa-check checkIcon" />
                                <span>{{ "데이터 유효성 검증" }}</span>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="$refs['independ-scatter-modal'].show()"
                                >
                                    {{ "상관도 보기" }}
                                </button>
                            </div>
                        </div>
                        <div>
                            <mnv-validation-table :resultData="resultData"></mnv-validation-table>
                        </div>
                    </div>
                    <b-modal
                        ref="independ-scatter-modal"
                        id="independ-scatter-modal"
                        centered
                        @hide="$refs['independ-scatter-modal'].hide()"
                        @shown="renderChartAfterModal"
                    >
                        <template #modal-header>
                            <h5 class="m-0">{{ "독립변수 상관도 분석" }}</h5>
                        </template>
                        <div>
                            <mnv-independ-var-scatter-panel
                                ref="independVarScatterPanel"
                                :resultData="resultData"
                                :detailInfo="detailInfo"
                            ></mnv-independ-var-scatter-panel>
                        </div>
                        <template #modal-footer>
                            <div style="display: flex; flex: 1;">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    style="flex: 1;"
                                    @click="$refs['independ-scatter-modal'].hide()"
                                >
                                    {{ "닫기" }}
                                </button>
                            </div>
                        </template>
                    </b-modal>

                    <div class="modelSignificance borderStyle">
                        <div
                            class="smallTitle"
                            style="display: flex; justify-content: space-between; align-items: center;"
                        >
                            <div>
                                <i class="fa fas fa-check checkIcon" />
                                <span>{{ "모델 유의성 평가" }}</span>
                            </div>
                            <b-form-select
                                v-model="cutoff"
                                :options="[{ value: null, text: '모델 유의 기준 (pValue)' }, ...pValueCutoff]"
                                :style="{ width: '200px' }"
                            ></b-form-select>
                        </div>
                        <div v-for="(key, index) in mainKey" :key="index">
                            <div class="modelSignificanceTableTitle">{{ key }}</div>
                            <div>
                                <mnv-significance-table
                                    :resultData="resultData"
                                    :cutoff="cutoff"
                                    :modelKey="key"
                                    :selectedModelMap="selectedModelValue(key)"
                                    :selectEquation="selectEquation"
                                    :r2cutOff="r2cutOff"
                                ></mnv-significance-table>
                            </div>
                        </div>
                    </div>

                    <div class="selectModelFomula borderStyle">
                        <div class="smallTitle"><i class="fa fas fa-check checkIcon" />분석 모델식 선택</div>
                        <div v-for="(key, index) in mainKey" :key="`${key}-select-equation`">
                            <div class="selectFomulaBox">
                                <div
                                    style="display: flex; justify-content: flex-end; width: 300px; margin-right: 10px;"
                                >
                                    <span>{{ `${key} : ` }}</span>
                                </div>
                                <b-form-select
                                    :value="validSelectEquation(index)"
                                    @input="updateSelectEquation(index, $event ?? null)"
                                    :options="[{ value: null, text: '분석 모델식을 선택하세요.' }, ...evalList[key]]"
                                ></b-form-select>
                                <!-- <button
                                    type="button"
                                    class="btn btn-secondary showSignificanceButton"
                                    @click="showModelSignificanceModal(key)"
                                >
                                    {{ "모델 유의성 평가" }}
                                </button> -->
                            </div>
                        </div>
                        <div style="display: flex; flex: 1;">
                            <button
                                type="button"
                                class="analysisButton btn btn-primary createReportButton"
                                :disabled="selectEquationIsNull"
                                @click="generateReport()"
                            >
                                <b-spinner small v-if="isLoading" variant="primary" label="Spinning"></b-spinner>
                                <span v-else>{{ "보고서 생성" }}</span>
                            </button>
                        </div>
                    </div>
                    <!-- 모델 유의성 평가 모달 -->
                    <!-- <b-modal
                        ref="modelSignificanceModal"
                        centered
                        id="significance-modal-body"
                        @hide="hideModelSignificanceModal"
                    >
                        <template #modal-header>
                            <h5 class="modal-title">{{ "모델 유의성 평가" }}</h5>
                        </template>
                        <div v-if="modelKey" class="modelSignificance borderStyle">
                            <div
                                class="smallTitle"
                                style="display: flex; justify-content: space-between; align-items: center;"
                            >
                                <div>
                                    <i class="fa fas fa-check checkIcon" />
                                    <span>{{ "모델 유의성 평가" }}</span>
                                </div>
                                <b-form-select
                                    v-model="cutoff"
                                    :options="[{ value: null, text: '모델 유의 기준 (pValue)' }, ...pValueCutoff]"
                                    :style="{ width: '200px' }"
                                ></b-form-select>
                            </div>
                            <div>
                                <mnv-significance-table
                                    :resultData="resultData"
                                    :cutoff="cutoff"
                                    :modelKey="modelKey"
                                ></mnv-significance-table>
                            </div>
                        </div>
                        <template #modal-footer>
                            <div style="display: flex; flex: 1;">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    style="flex: 1;"
                                    @click="hideModelSignificanceModal"
                                >
                                    {{ "닫기" }}
                                </button>
                            </div>
                        </template>
                    </b-modal> -->
                </div>
                <!-- </transition> -->

                <!-- 분석 결과 -->
                <!-- </b-tab> -->
                <!-- <b-tab title="분석 보고서"> -->
                <!-- 분석 결과 탭의 내용 -->
                <!-- <transition name="fade-slide" mode="out-in"> -->
                <div
                    v-if="mnvTab === 1"
                    class="MNVResult borderStyle"
                    ref="mnvResultComponentRef"
                    key="analysis-report"
                >
                    <div class="smallTitle MnvReportTitle">
                        <i class="fa fas fa-check checkIcon" />
                        <span class="mb-0">{{ "분석 결과" }}</span>
                    </div>
                    <mnv-analysis-result-panel
                        :mnvReportData="computedMnvReportData"
                        :resultData="resultData"
                        :detailInfo="detailInfo"
                        :periodName="periodName"
                        :reportMnvType="reportMnvType"
                        :activeTab="mnvTab"
                        @activeTabMnvReport="activeTabMnvReport"
                    />

                    <!-- <b-spinner small v-else variant="primary" label="Spinning"></b-spinner> -->
                </div>
                <!-- </transition> -->
                <!-- </b-tab> -->
                <!-- </b-tabs> -->
            </transition-group>
        </b-card>
    </div>
</template>

<script>
import MnvAnalysisPeriodTable from "../tables/MnvAnalysisPeriodTable.vue";
import MnvAnalysisTargetTable from "../tables/MnvAnalysisTargetTable.vue";
import MnvSignificanceTable from "../tables/MnvSignificanceTable.vue";
import MnvValidationTable from "../tables/MnvValidationTable.vue";
import MnvBasePeriodTable from "../tables/MnvBasePeriodTable.vue";
import MnvReportPeriodTable from "../tables/MnvReportPeriodTable.vue";
import MnvIndependVarScatterPanel from "../charts/MnvIndependVarScatterPanel.vue";
import MnvAnalysisResultPanel from "../tables/MnvAnalysisResultPanel.vue";
import backEndApi from "@src/api/backEndApi";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: [
        "detailInfo",
        "resultData",
        "adjustData",
        "fixIndependData",
        "mode",
        "existMnvReportFlag",
        "reportMnvType",
        "mnvTab",
    ],
    components: {
        MnvAnalysisPeriodTable,
        MnvAnalysisTargetTable,
        MnvSignificanceTable,
        MnvValidationTable,
        MnvBasePeriodTable,
        MnvReportPeriodTable,
        MnvIndependVarScatterPanel,
        MnvAnalysisResultPanel,
    },
    data() {
        return {
            selectEquation: [],
            cutoff: 0.05,
            isLoading: false,
            mnvReportData: null,
            // mnvReportData: {},
            isSuccess: null,
            localFactor: {
                ...this.resultData.variable.main.factor,
            },
            periodName: [],
            baselineErrorVal: 0,
            reportErrorVal: 0,
            modelKey: null,
            // activeTab: 0,
            popupMessages,
            r2cutOff: 0.8,
            selectedModelMap: new Map(),
        };
    },
    async mounted() {
        this.mainKey.forEach(() => {
            this.selectEquation.push(null);
        });

        this.resultData.variable.main.forEach((item) => {
            this.localFactor[item.key] = item.factor;
        });

        if (this.resultData) {
            this.initPeriodName();
            // 임시 주석 by woonsik 2025-01-02
            // this.calcTotalErrorVal();
        }
    },

    methods: {
        changeTab(bool) {
            this.$emit("update:MnvTab", bool);
        },
        validSelectEquation(index) {
            const availableOptions = this.evalList[this.mainKey[index]]?.map((opt) => opt.value) || [];
            return availableOptions.includes(this.selectEquation[index]) ? this.selectEquation[index] : null;
        },
        updateSelectEquation(index, value) {
            this.$set(this.selectEquation, index, value);

            if (this.selectEquation.length > this.mainKey.length) {
                this.selectEquation = this.selectEquation.slice(0, this.mainKey.length);
            }
        },

        mappingSelectedModel(data) {
            // 기존 데이터와 새로운 데이터를 비교하면서 변경
            this.selectEquation = Object.keys(data.selectedModel).map((key) => {
                return data.selectedModel[key].equation || null;
            });

            return 0;
        },
        handleAnalysisStatus(data, searchInfo) {
            const { analysisStatus } = data;

            if (analysisStatus === "CheckVariableData") {
                throw new Error("유효하지 않은 데이터를 포함한 변수가 존재합니다.");
            }

            if (analysisStatus === "CheckUserVariables") {
                throw new Error("사용자 정의 변수에 오류가 존재합니다.");
            }

            if (analysisStatus === "Success") {
                this.processSuccessfulAnalysis(data, searchInfo);
            }
        },

        processSuccessfulAnalysis(data, searchInfo) {
            // Mnv 리포트를 생성하는 방식(분석 준비 버튼을 클릭하는 경우, MNV 분석준비 탭 하단의 리포트생성 버튼을 클릭하는경우 )
            // 에 따라 데이터 후처리를 위한 이벤트발생
            //같은 api를 분석준비를 할때도 쓰고 리포트 생성할때도 쓰기때문에 api call의
            //res중 model 프로퍼티가 달라진다. 따라서 상황에 따라 model프로퍼티를 덮어쓰기위한 이벤트 발생
            // 해당컴포넌트의 computed 속성중 computedMnvReportData 참고
            this.changeGenerateMnvReportType("generateReport");
            this.alertNoti(popupMessages.ENERGY_ANALYSIS_REPORT_CREATE_SUCCESS_MESSAGE);
            this.isSuccess = data.analysisStatus;
            this.mnvReportData = data;

            //detailInfo의 modelOptions와 varOptions를 업데이트하여 추후에 저장 할 시
            //이 데이터를 사용하여 저장 api call을 함
            this.updateDetailInfo(searchInfo);
            // this.activeTab = 1;
            this.changeTab(1);
        },

        updateDetailInfo(searchInfo) {
            if (searchInfo.modelContext) {
                this.detailInfo.modelOptions = searchInfo.modelContext;
                if (this.localFactor) {
                    Object.keys(this.localFactor).forEach((factorKey) => {
                        this.detailInfo.modelOptions[factorKey].factor = this.localFactor[factorKey];
                    });
                }
            }

            if (!this.detailInfo.varOptions) {
                this.detailInfo.varOptions = {};
            }

            this.detailInfo.varOptions.MNVData = {
                baseline: searchInfo.baseline,
                report: searchInfo.report,
            };
        },
        activeTabMnvReport() {
            this.$emit("activeTabMnvReport");
        },
        changeGenerateMnvReportType(type) {
            this.$emit("changeGenerateMnvReportType", type);
        },
        renderChartAfterModal() {
            if (this.$refs["independVarScatterPanel"]) {
                this.$refs["independVarScatterPanel"].renderCharts();
            }
        },
        showPropMgmtModal() {
            this.$emit("prop-mgmt-modal");
        },
        async showModelSignificanceModal(key) {
            this.modelKey = key;
            await this.$refs["modelSignificanceModal"].show();
        },
        async hideModelSignificanceModal() {
            await this.$refs["modelSignificanceModal"].hide();
            this.modelKey = null;
        },
        calcTotalErrorVal() {
            //베이스라인 기간 데이터, 보고기간 데이터 테이블에서 error 값 갯수계산
            this.baselineErrorVal = this.numberOfFalsy(this.resultData.data.baseline[this.mainKey]);
            this.resultData.data.report.forEach((item) => {
                this.reportErrorVal += this.numberOfFalsy(item[this.mainKey]);
            });
        },
        numberOfFalsy(arr) {
            // arr가 undefined 또는 null이면 빈 배열([])로 대체
            if (!Array.isArray(arr)) {
                return 0;
            }

            return arr.reduce((acc, cur) => acc + (cur === undefined || cur === 0 || cur === null ? 1 : 0), 0);
        },
        onUpdatePeriodName(period) {
            this.periodName = [...period];
        },
        initPeriodName() {
            this.periodName.push("베이스라인 기간");
            this.resultData.data.report.forEach((_, i) => this.periodName.push(`보고기간${i + 1}`));
        },
        onUpdateFactor(factor, key) {
            this.localFactor[key] = factor;
            this.$emit("updateFactor", factor, key);
        },
        isEmpty(input) {
            if (
                typeof input === "undefined" ||
                input === null ||
                input === "" ||
                input === "null" ||
                input.length === 0 ||
                (typeof input === "object" && !Object.keys(input).length)
            ) {
                return true;
            } else return false;
        },
        async generateReport() {
            // const findMainVariable = this.detailInfo.variables.find((item) => item.roleType == "Depend");

            const mvnSearchInfo2 = {
                startOfYear: 1,
                ptIdx: this.detailInfo.ptIdx,
                timeDsvn: this.detailInfo.timeDsvn,
                fromDate: this.detailInfo.fromDate,
                toDate: this.detailInfo.toDate,
                variables: [],
                compareType: this.detailInfo.compareType,
                fromDateComp: this.detailInfo.fromDateComp,
                toDateComp: this.detailInfo.toDateComp,
                datasetMode: "Divided",
                dispUnit: this.detailInfo.dispUnit,
                modelContext: {},
                // baseline: {
                //     adjustData: {},
                // },
                // report: {
                //     adjustData: {},
                // },
                baseline: {},
                report: {},
                // adjustData: {},
            };
            /**
             * modelContext -> modelOptions 변경,
             * modelOptions 에 담아서 모델 저장하면 나중에 모델 선택시
             * 해당 값 그대로 가져와서 분석 결과 바로 조회 할 수 있음 made by woonsik
             */
            // mvnSearchInfo2.modelContext[this.detailInfo.modelOptions.dependent] = this.detailInfo.modelOptions;
            // mvnSearchInfo2.modelContext[this.detailInfo.modelOptions.dependent].factor = this.localFactor;

            this.resultData.variable.main.forEach((item) => {
                mvnSearchInfo2.modelContext[item.key] = this.resultData.model[item.key].find((model) =>
                    this.selectEquation.some((eq) => eq == model.equation)
                );
            });

            this.detailInfo.variables.forEach((pt) => {
                mvnSearchInfo2.variables.push(pt);
            });

            if (!this.isEmpty(this.localFactor) && Array.isArray(mvnSearchInfo2.variables)) {
                Object.keys(this.localFactor).forEach((factorKey) => {
                    // mvnSearchInfo2.variables 배열 중 name이 factorKey인 항목을 찾는다
                    const targetItem = mvnSearchInfo2.variables.find((item) => item.name === factorKey);

                    // 찾았다면 factor를 할당
                    if (targetItem) {
                        // factor 프로퍼티가 없을 경우를 대비해 초기화
                        if (!targetItem.factor) {
                            targetItem.factor = {};
                        }
                        // this.localFactor[factorKey]를 factor에 넣는다
                        targetItem.factor = this.localFactor[factorKey];
                    }
                });
            }
            if (this.existAdjustData(this.detailInfo.varOptions?.MNVData)) {
                // mvnSearchInfo2.adjustData = this.adjustData;
                ["baseline", "report"].forEach((type) => {
                    mvnSearchInfo2[type] = this.detailInfo.varOptions.MNVData[type];
                });
            }

            if (!this.isEmpty(this.adjustData) && Object.keys(this.adjustData).length > 0) {
                // mvnSearchInfo2.adjustData = this.adjustData;
                ["baseline", "report"].forEach((type) => {
                    mvnSearchInfo2[type] = this.adjustData[type];
                });
            }

            await this.analyzeMNV(mvnSearchInfo2);
        },
        async analyzeMNV(searchInfo) {
            this.isSuccess = null;
            this.isLoading = true;
            try {
                let result = await backEndApi.analysisModel.analyzeMNV(searchInfo);

                if (result.status == 200) {
                    // this.mappingSelectedModel(result.data);

                    //리포트 생성 api call후 처리
                    this.handleAnalysisStatus(result.data, searchInfo);
                } else {
                    // this.mnvReportData = {};
                    this.mnvReportData = null;
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                // 예외 처리
                console.error(e);
                // this.alertNoti(`${e.message}`);
            } finally {
                this.isLoading = false;
            }
        },
        // modelOptions에서 R2가 있음으로서 MNV 분석 데이터만 필터링하는 함수입니다.
        existModelContext(modelOptions) {
            if (!modelOptions || typeof modelOptions !== "object") return false;

            Object.keys(modelOptions).forEach((key) => {
                if (modelOptions[key] === null) {
                    delete modelOptions[key];
                }
            });

            if (Object.keys(modelOptions).length === 0) {
                return false;
            }

            const filtered = Object.fromEntries(
                Object.entries(modelOptions).filter(([, value]) => value && value.hasOwnProperty("R2"))
            );

            return filtered;
        },
        existAdjustData(adjustData) {
            // 1. adjustData가 존재하고 객체여야 함.
            if (!adjustData || typeof adjustData !== "object") {
                return false;
            }

            // 2. varOptions 안에 MNVData 프로퍼티가 없으면 기본값으로 추가
            if (!("MNVData" in adjustData)) {
                adjustData.MNVData = {};
            }

            // 3. baseline과 report 프로퍼티가 있는지 확인 (없으면 false 반환)
            if (!("baseline" in adjustData) || !("report" in adjustData)) {
                return false;
            }

            let adjustObj = {};

            // 4. baseline 데이터 검증: null이 아니고, 객체 또는 배열이어야 함.
            if (
                adjustData.baseline &&
                (typeof adjustData.baseline === "object" || Array.isArray(adjustData.baseline))
            ) {
                adjustObj.baseline = adjustData.baseline;
            } else {
                adjustObj.baseline = {};
            }

            // 5. report 데이터 검증: null이 아니고, 객체 또는 배열이어야 함.
            if (adjustData.report && (typeof adjustData.report === "object" || Array.isArray(adjustData.report))) {
                adjustObj.report = adjustData.report;
            } else {
                adjustObj.report = {};
            }

            // 6. MNVData도 유효한지 검증 (이미 추가되어 있으므로)
            if (adjustData.MNVData && (typeof adjustData.MNVData === "object" || Array.isArray(adjustData.MNVData))) {
                adjustObj.MNVData = adjustData.MNVData;
            } else {
                adjustObj.MNVData = {};
            }

            // 7. baseline과 report가 모두 비어있는 경우 유효하지 않다고 판단
            const isEmptyData = (data) =>
                (Array.isArray(data) && data.length === 0) ||
                (typeof data === "object" && !Array.isArray(data) && Object.keys(data).length === 0);

            if (isEmptyData(adjustObj.baseline) && isEmptyData(adjustObj.report)) {
                return false;
            }

            return adjustObj;
        },
    },
    watch: {
        existMnvReportFlag: {
            handler(newVal) {
                if (newVal === true) {
                    this.$nextTick(() => {
                        // this.activeTab = 1; // 분석 결과 탭으로 이동
                        this.changeTab(1);
                    });
                }
            },
            immediate: true,
        },

        // resultData(newVal) {
        //     if (newVal) {
        //         console.log("newval in resultData watch", newVal);
        //         this.calcTotalErrorVal();
        //     }
        // },

        resultData: {
            handler(newVal) {
                if (newVal) {
                    if (newVal.analysisStatus === "Success") {
                        this.mappingSelectedModel(newVal);
                    }

                    // this.mappingSelectedModel(newVal)
                    this.calcTotalErrorVal();
                }
            },
            deep: true, // 객체 내부 속성이 변경될 때도 감지
            immediate: true, // 마운트 시점에 한 번 실행
        },
    },
    computed: {
        selectedModelValue() {
            return (key) => this.selectedModelMap.get(key) || null;
        },
        computedMnvReportData() {
            // 만약 reportMnvType가 generateReport라면 현재 컴포넌트에서 API로 받아온 mnvReportData를 그대로 사용
            if (this.reportMnvType && this.reportMnvType === "generateReport") {
                return this.mnvReportData;
            }
            // reportMnvType가 existReport라면 상위 컴포넌트에서 내려받은 resultData를 복제한 후,
            // selectedModel 프로퍼티가 존재하면 이를 model 프로퍼티에 덮어씁니다.
            else if (this.reportMnvType && this.reportMnvType === "existReport") {
                // resultData가 객체라고 가정하고 깊은 복사를 진행 (예: JSON deep clone)
                let clonedData = JSON.parse(JSON.stringify(this.resultData));
                if (clonedData && clonedData.selectedModel) {
                    clonedData.model = clonedData.selectedModel;
                    // this.mappingSelectedModel(clonedData);
                }
                return clonedData;
            }
            // 그 외의 경우 기본값으로 API 호출 결과를 사용
            else {
                return this.mnvReportData;
            }
        },

        selectEquationIsNull() {
            return this.selectEquation.some((item) => item == null);
        },
        mainKey() {
            return this.resultData.variable.main.map((item) => item.key);
        },
        pValueCutoff() {
            const values = this.$store.state.commonCodes.PValueCutoff.map((item) => {
                return {
                    value: Number(item.value),
                    text: item.text,
                };
            });
            return values ?? [];
        },
        // evaluationSinificance() {
        //     const mainKey = this.resultData.variable.main[0].key;
        //     const modelList = this.resultData.model ? this.resultData.model[mainKey] : [];
        //     const list = modelList.map((item) => {
        //         // 모델의 p-value 테스트는 model의 pValue로만 하는 것이 맞는 것 같음. 임시로 true 설정 확정은 아님 by badblock.2024-12-25
        //         // const pValue = item.pValues.every((value) => value < this.cutoff);
        //         const pValue = true;
        //         const modelPValue = item.modelPValue < this.cutoff ? true : false;
        //         // const R2 = item.R2 > 50 ? true : false;
        //         // R2값과 adjustR2값이 백분율에서 0 ~ 1의 소수로 변경됨.
        //         const R2 = item.R2 > 0.5 ? true : false;

        //         // const adjustedR2 = item.adjustedR2 > 50 ? true : false;
        //         const adjustedR2 = item.adjustedR2 > 0.5 ? true : false;

        //         item.significance = pValue && modelPValue && R2 && adjustedR2;

        //         // console.warn( `evaluationSinificance: model pValue: ${modelPValue}, R2: ${R2}, adjusted R2: ${adjustedR2}` );

        //         return pValue && modelPValue && R2 && adjustedR2 ? true : false;
        //         // return true;
        //     });

        //     return list;
        // },
        evalList() {
            // let list = [];
            let list = {};

            // const mainKey = this.resultData.variable.main[0].key;
            const mainKey = this.resultData.variable.main.map((item) => item.key);

            // const modelList = this.resultData.model ? this.resultData.model[mainKey] : [];
            let modelList = {};
            mainKey.forEach((key) => {
                modelList[key] = this.resultData.model ? this.resultData.model[key] : [];
            });

            // modelList.forEach((item) => {
            //     // 모델의 p-value 테스트는 model의 pValue로만 하는 것이 맞는 것 같음. 임시로 true 설정 확정은 아님 by badblock.2024-12-25
            //     // const pValue = item.pValues.every((value) => value < this.cutoff);
            //     const pValue = true;
            //     const modelPValue = item.modelPValue < this.cutoff ? true : false;
            //     const R2 = item.R2 > 0.5 ? true : false;
            //     const adjustedR2 = item.adjustedR2 > 0.5 ? true : false;

            //     item.significance = pValue && modelPValue && R2 && adjustedR2;
            // });
            mainKey.forEach((key) => {
                modelList[key].forEach((item) => {
                    // const pValue = true;
                    const pValue = item.pValues.slice(1).every((value) => value < this.cutoff);
                    const modelPValue = item.modelPValue < this.cutoff ? true : false;
                    const R2 = item.R2 > this.r2cutOff ? true : false;
                    const adjustedR2 = item.adjustedR2 > this.r2cutOff ? true : false;
                    item.significance = pValue && modelPValue && R2 && adjustedR2;
                    // item.significance =  modelPValue && R2 && adjustedR2;
                });
            });

            // new Version by badblock.2024.12.26
            // list = modelList
            //     .filter((model) => model.significance)
            //     .sort((a, b) => b.adjustedR2 - a.adjustedR2)
            //     .map((filterd) => {
            //         return {
            //             value: filterd.equation,
            //             text: `[${(filterd.adjustedR2 * 100).toFixed(1)}%] ${filterd.equation}`,
            //         };
            //     });
            mainKey.forEach((key) => {
                list[key] = modelList[key]
                    .filter((model) => model.significance)
                    .sort((a, b) => b.adjustedR2 - a.adjustedR2)
                    .map((filterd) => {
                        return {
                            value: filterd.equation,
                            text: `[${(filterd.adjustedR2 * 100).toFixed(1)}%] ${filterd.equation}`,
                        };
                    });
            });

            console.warn("evalList : ", list);

            return list;
        },
    },
};
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    width: 100%;
}

.fade-slide-enter-from {
    opacity: 0;
    transform: translateY(15px);
}

.fade-slide-leave-to {
    opacity: 0;
    transform: translateY(-15px);
}

.MnvContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    padding: 10px;
}
.smallTitle {
    font-weight: bold;
    margin-bottom: 5px;
}
.MNVtable {
    width: 100%;
}
.info,
.analysisTarget,
.analysisPeriod,
.baselinePeriodData,
.reportPeriodData,
.dataValidation,
.modelSignificance,
.selectModelFomula,
.MNVResult {
    width: 100%;
    min-height: 50px;
    flex-grow: 1;
    padding: 10px;
    box-sizing: border-box;
}
.borderStyle {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 10px 0px;
}
.info {
    /* background-color: aqua; */
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.errorBadge {
    margin-left: 4px;
    background-color: #e57373;
    color: white;
}

.analysisPeriod {
    width: 100%;
    /* background-color: blanchedalmond; */
}

.selectFomulaBox {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}
.createReportButton {
    /* width: 100px;
    margin-left: 50px; */
    flex: 1;
}
.showSignificanceButton {
    width: 150px;
    margin-left: 50px;
}
.checkIcon {
    margin-right: 5px;
}
.independentVarScatter {
    width: 100%;
    min-height: 50px;
    flex-grow: 1;
    box-sizing: border-box;
}
.modelSignificanceTableTitle {
    font-size: 12px;
    font-weight: bold;
}
</style>
